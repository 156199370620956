/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import MarketplaceCard from './MarketplaceCard';
import {
	 Container,
 Header,
	// ItemsWrapper,
	// Items,
	// Filters,
	// Sort,
	CardGrid
} from '../styles/Marketplace/MarketplaceGridStyles';
import Footer from './Footer'
//  import { Books } from '../Data';
import { Books, updateSoldData } from '../Data';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MarketplaceGrid = ({handleMoreInfoClick}) => {
	const [t] = useTranslation();
	const [updatedBooks, setUpdatedBooks] = useState([]);
	// For rendering last book at certain time
  
	 
	useEffect(() => {
		async function fetchData() {
			await updateSoldData();

			const updatedBookData = [...Books];
			setUpdatedBooks(updatedBookData);
			
		}

		fetchData();
	}, []);


	return (
		<>
			<Container>
				{/* <Header>
					<h2> {t('marketplacegrid.text1')}</h2>
				</Header> */}
				{/* <ItemsWrapper>
					<Items> 34359 items</Items>
					<Filters>Filter +</Filters>
					<Sort>Sort by +</Sort>
				</ItemsWrapper> */}
				<CardGrid>
					{/* {Books.map((book,id)=>(
						<MarketplaceCard handleMoreInfoClick={handleMoreInfoClick} key={id} {...book}/>
					))} */}
					{ Books.map((book, id) => (
						<MarketplaceCard
							handleMoreInfoClick={() => handleMoreInfoClick(book)}
							key={id}
							{...book}
						/>
					))}
				
				</CardGrid>
			</Container>
			<Footer />
		</>
	);
};

export default MarketplaceGrid;