/* eslint-disable no-unused-vars */
import { Title, 
	TextContainer} from '../../../styles/AboutUs/TextAbout4Styles'
import { useTranslation } from 'react-i18next';
const TextAbout4 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				<span>{t('about.text8')}</span> {t('about.text9')}
			</Title>
			{/* <Title>	
				<span>Reduce CO2 emissions</span> promoting the transition
				from paper to digital books
			</Title> */}
		</TextContainer>
	);
};

export default TextAbout4;
