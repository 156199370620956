/* eslint-disable no-useless-return */
/* eslint-disable spaced-comment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
	Container,
	ContainerCountDown,
	Header,
	CountDown,
	Count,
	Text,
} from '../../styles/CountDownTimer/CountDownTimerStyles';
import {getRemainingTimeUntilMsTimestamp} from './Utils/CountDownUtils'

const defaultRemainingTime = {
	seconds: '00',
	minutes: '00',
	hours: '00',
	days: '00',
};

const CountDownTimer = ({ countdownTimestampsMs }) => {
	const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

	useEffect(() => {
		const intervalId = setInterval(() => {
			updateRemainingTime(countdownTimestampsMs);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [countdownTimestampsMs]);
	
	const updateRemainingTime = countdown => {
		setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown))
	};

	return (
		<>
			<Container>
				<Header>First Release</Header>
				<ContainerCountDown>
					<CountDown>
						<Count>{remainingTime.days}</Count>
						<Text>DAYS</Text>
					</CountDown>
					<CountDown>
						<Count>{remainingTime.hours}</Count>
						<Text>HOURS</Text>
					</CountDown>
					<CountDown>
						<Count>{remainingTime.minutes}</Count>
						<Text>MINUTES</Text>
					</CountDown>
					<CountDown>
						<Count>{remainingTime.seconds}</Count>
						<Text>SECONDS</Text>
					</CountDown>
				</ContainerCountDown>
			</Container>
		</>
	);
};

export default CountDownTimer;




