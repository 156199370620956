import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';



export const TextContainer = styled.div`
	// width: 100%;
	max-width: 1728px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;



	@media only screen and ${breakpoint.device.lg} {
		margin: auto;
	}

	// @media only screen and ${breakpoint.device.l} {
	// 	width: 100%;
	// 	margin: auto;
	// }
	// @media only screen and ${breakpoint.device.m} {
	// 	width: 100%;
	// 	margin: auto;
	// }

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		max-width: 90%;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 100%;
		margin: auto;
	}
`;

// export const SVG = styled.div`
// 	background-size: auto;
// 	background-position: center;
// 	background-repeat: no-repeat;

// 	// @media only screen and ${breakpoint.device.s} {
// 	// 	background-size: auto;
// 	// 	background-position: center;
// 	// 	background-repeat: no-repeat;
// 	// }
// `;

export const Title = styled.h1`
	text-align: center;
	margin: 5.5em 1em 1em;
	width: 60%;

	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.lg} {
		text-align: left;

		margin: 3.5em 1em 1em 3em;
		// margin: 1.5em 1em 1em 3em;fixing
		// max-width: 100%;

		// text-align: left;
		// margin: 4em auto;
		// margin-bottom: 2em;
		// /* margin: 3em 0em 1em 2em; */
		width: 65%;
	}

	@media only screen and ${breakpoint.device.l} {
		text-align: left;
		margin: 3.5em 1em 1em 3em;
		width: 69%;
		padding: 0;
	}

	@media only screen and ${breakpoint.device.m} {
		padding-left: 1em;
		margin: 4em 3em auto;
		// margin: 4em 1em 2em 2em;
		width: 67%;
		font-size: 60px;
		text-align: left;
	}

	@media only screen and ${breakpoint.device.s} {
		// padding-bottom: 0;
		// padding-top: 1.2em;
		width: 92%;
		margin: 8em 1em 1em 1em;
		font-size: 40px;
		text-align: left;
	}

	@media only screen and ${breakpoint.device.xs} {
		padding: 0;
		width: 90%;
		margin: 7em 1em 1em 1em;
		font-size: 35px;
		text-align: left;
	}
`;


