/* eslint-disable no-unused-vars */
import { Title, TextContainer, LinkMarketplace } from '../../../styles/Readers/TextReadersStyles';
import { useTranslation } from 'react-i18next';
const TextReaders = () => {
	const [t, i18n] = useTranslation('global');
	return (
		<TextContainer>
			<Title>
				{t('readers.text1')}{' '}
				<span>
					<LinkMarketplace to='/marketplace'>
						{' '}
						{t('readers.text2')}
					</LinkMarketplace>	
				</span>{' '}
				{t('readers.text3')}
			</Title>
			{/* <Title>
				If you are a literature lover and{' '}
				<span>
					<LinkMarketplace to='/marketplace'> looking to discover new authors</LinkMarketplace>,
				</span>{' '}
				you are in the right place.
			</Title> */}
		</TextContainer>
	);
};

export default TextReaders;
