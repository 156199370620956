/* eslint-disable no-unused-vars */
import { useState } from 'react';
import {
	Title,
	TextContainer,
	SocialLinks,
} from '../../../styles/Readers/TextReaders5Styles';
import { useTranslation } from 'react-i18next';

const TextReaders5 = () => {
	const [t, i18n] = useTranslation('global');
	const [showLinks, setShowLinks] = useState(false);

	const handleJoinClick = () => {
		setShowLinks(!showLinks);
	};

	return (
		<TextContainer>
			<Title>
				{t('readers.text15')}{' '}
				<span>
					{' '}
					<a onClick={handleJoinClick}>{t('readers.text16')}</a>{' '}
				</span>{' '}
				{t('readers.text17')}
			</Title>

			{showLinks && (
				<SocialLinks>
					<a
						src='linkedInLiberada'
						href='https://es.linkedin.com/company/liberada?trk=public_profile_topcard-current-company'
						target='_blank'
						rel='noreferrer'
					>
						LinkedIn
					</a>
					<a
						src='instagramLiberada'
						href='https://instagram.com/liberadanft?igshid=YmMyMTA2M2Y='
						target='_blank'
						rel='noreferrer'
					>
						Instagram
					</a>
					<a
						src='twitterLiberada'
						href='https://twitter.com/LiberadaNFT'
						target='_blank'
						rel='noreferrer'
					>
						Twitter
					</a>
					<a
						src='facebookLiberada'
						href='https://www.facebook.com/people/NFT-Books-Liberada/100090957735416/'
						target='_blank'
						rel='noreferrer'
					>
						Facebook
					</a>
				</SocialLinks>
			)}
		</TextContainer>
	);
};

export default TextReaders5;
