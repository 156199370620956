/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */

import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
	NavContainer,
	NavWrapper,
	LogoDiv,
	Menu,
	MenuItem,
	MenuItemLink,
	Burguer,
	DropdownContainer,
	DropdownMenu,
	DropdownItem,
} from '../styles/NavbarWhiteStyles';

import { ReactComponent as BurguerButton} from '../assets/images/BurguerButton.svg';
import { ReactComponent as XButtonBurguer } from '../assets/images/XButtonBurguer.svg';
 import LogoLiberada from './LogoLiberada'

//  import {  AnimatePresence } from 'framer-motion';

 import { useTranslation } from 'react-i18next';
 import i18next from 'i18next';

 const languages = [
		{
			code: 'en',
			name: 'ENG',
			countryCode: 'en',
		},
		
		{
			code: 'es',
			name: 'ESP',
			countryCode: 'es',
		},
		
		// {
		// 	code: 'ar',
		// 	name: 'العربية',
		// 	dir: 'rtl',
		// 	countryCode: 'sa',
		// },
 ];

const NavbarWhite = () => {
	const [t] = useTranslation();
	const [click, setClick] = useState(false);
	const [logoColor, setLogoColor] = useState(false);
	const [scrollY, setScrollY] = useState(0);
	//TESTING LINKS STAY WHITE BEHAVIOR-Works with isActive css
	const location = useLocation();

	const currentLanguageCode = 'en';
	const currentLanguage = languages.find(l => l.code === currentLanguageCode);
	const [selectedLanguage, setSelectedLanguage] = useState(currentLanguageCode);

	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	// // Load the selected language from localStorage on component mount
	useEffect(() => {
		const storedLanguage = localStorage.getItem('selectedLanguage');
		if (storedLanguage) {
			setSelectedLanguage(storedLanguage);
			i18next.changeLanguage(storedLanguage);
		}
	}, []);


	//when click it changes the logo to white
	const handleClick = () => {
		setClick(!click);
		setLogoColor(!logoColor);
	};

	const handleLinkClick = e => {
		e.stopPropagation(); // prevent the event from bubbling up to parent elements and logo turning white when doble click the menu links.
		handleClick();
	};

	// Scroll change background
	useEffect(() => {
		const handleScroll = () => {
			setScrollY(window.scrollY);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		// <AnimatePresence>
		<NavContainer>
			<NavWrapper
				style={{
					// position: 'sticky',
					top: 0,
					zIndex: 99,
					// overflow: 'hidden',
					WebkitBackdropFilter: scrollY > 0 && !click ? 'blur(10px)' : 'none',
					backdropFilter: scrollY > 0 && !click ? 'blur(10px)' : 'none',
					transition: 'backdrop-filter 0.3s',
				}}
			>
				<LogoDiv>
					<Link to='/' className='white-logo'>
						<LogoLiberada
							className='logo'
							style={!logoColor ? { color: 'black' } : { color: 'white' }}
						/>
					</Link>
				</LogoDiv>
				<Burguer onClick={() => handleClick()}>
					{click ? (
						<XButtonBurguer className='x-burguer' />
					) : (
						<BurguerButton className='burguer-btn' />
					)}
				</Burguer>
				<Menu click={click}>
					<MenuItem>
						<Link to='/'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/'}
							>
								{t('navbar.text1')}
							</MenuItemLink>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link to='/authors'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/authors'}
							>
								{t('navbar.text2')}
							</MenuItemLink>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link to='/readers'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/readers'}
							>
								{t('navbar.text3')}
							</MenuItemLink>
						</Link>
					</MenuItem>
					{/* <MenuItem>
							<MenuItemLink> {t('navbar.text4')}</MenuItemLink>
						</MenuItem> */}
					{/* <MenuItem>
						<Link to='/faqs'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/faqs'}
							>
								{t('navbar.text8')}
							</MenuItemLink>
						</Link>
					</MenuItem> */}
					<MenuItem>
						<Link to='/contactus'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/contactus'}
							>
								{t('navbar.text5')}
							</MenuItemLink>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link to='/marketplace'>
							<MenuItemLink
								onClick={handleLinkClick}
								isActive={location.pathname === '/marketplace'}
							>
								{t('navbar.text6')}
							</MenuItemLink>
						</Link>
					</MenuItem>
					<MenuItem>
						<DropdownContainer>
							<MenuItemLink onClick={toggleDropdown}>
								{t('navbar.text7')} &or;
							</MenuItemLink>
							<DropdownMenu open={isOpen}>
								{languages.map(({ code, name, countryCode }) => (
									<DropdownItem
										key={countryCode}
										selected={selectedLanguage === code}
									>
										<a
											href='#'
											onClick={() => {
												i18next.changeLanguage(code);
												setSelectedLanguage(code);
												localStorage.setItem('selectedLanguage', code);
												toggleDropdown();
											}}
										>
											<span>{name}</span>
										</a>
									</DropdownItem>
								))}
							</DropdownMenu>
						</DropdownContainer>
					</MenuItem>
				</Menu>
			</NavWrapper>
		</NavContainer>
		// </AnimatePresence>
	);
}

export default NavbarWhite;