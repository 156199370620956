/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */

import {
	AboutSection,
	AboutContainer,
	ModalView,
	Modal,
	CloseBtn,
} from '../../styles/AboutUs/AboutStyles';

import Footer from '../Footer';
import TextAbout from '../textComponents/About/TextAbout';
import TextAbout2 from '../textComponents/About/TextAbout2';
import TextAbout3 from '../textComponents/About/TextAbout3';
import TextAbout4 from '../textComponents/About/TextAbout4';
import TextAbout5 from '../textComponents/About/TextAbout5';
import TextAbout6 from '../textComponents/About/TextAbout6';
import whitepaperENG from '../../assets/Whitepaper/Liberada_Whitepaper_ENG.pdf';
import whitepaperESP from '../../assets/Whitepaper/Liberada_Whitepaper_ESP.pdf';

import NavbarWhite from '../NavbarWhite';
// import AboutUsFullBg from '../../assets/images/AboutUsFullBg.svg';
import AboutUsFullBg from '../../assets/images/AboutUsTEST.svg';
import mobileImage from '../../assets/images/AboutUsMobileTEST.svg';
// import mobileImage from '../../assets/images/AboutUsMobile.svg'
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
	const [t] = useTranslation();

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showTextAbout, setShowTextAbout] = useState(true); // Initialize to true to show the initial TextAbout components
	const [showButtons, setShowButtons] = useState(false);
	const [onClose, setOnClose] = useState(false);

	const imageUrl = windowWidth >= 700 ? AboutUsFullBg : mobileImage;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const handleDownloadClick = () => {
		setOnClose(false);
		setShowButtons(true);
	};

	const handleDownloadOptionClick = file => {
		switch (file) {
			case whitepaperENG:
				// Handle downloading whitepaperENG
				window.open(whitepaperENG, '_blank');
				break;
			case whitepaperESP:
				// Handle downloading whitepaperCAS
				window.open(whitepaperESP, '_blank');
				break;
			default:
				break;
		}
		// After handling the download-close the modal by hiding the buttons
		setShowButtons(false);
	};
	const handleOnClose = () => {
		setOnClose(true); // Set onClose state to true when the CloseBtn is clicked
	};

	return (
		<>
			{/* <AboutSection> */}
			<AboutSection
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
			>
				<NavbarWhite />
				{showTextAbout && (
					<>
						<TextAbout />
						<TextAbout2 />
						<TextAbout3 />
						<TextAbout4 />
						<TextAbout5 />
						<TextAbout6 handleDownloadClick={handleDownloadClick} />
					</>
				)}
				<>
					{showButtons && !onClose && (
						<ModalView>
							<Modal>
								<button
									download='Liberada_Whitepaper_ENG'
									onClick={() => handleDownloadOptionClick(whitepaperENG)}
								>
									Whitepaper ENG
								</button>
								<button
									download='Liberada_Whitepaper_ESP'
									onClick={() => handleDownloadOptionClick(whitepaperESP)}
								>
									Whitepaper ESP
								</button>
								<CloseBtn onClick={handleOnClose}>{t('about.text16')}</CloseBtn>
							</Modal>
						</ModalView>
					)}
				</>
				<Footer />
			</AboutSection>

			{/* </AboutSection> */}
		</>
	);
};

export default About;
