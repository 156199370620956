import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';



export const TextContainer = styled.div`
	width: 33%;
	display: flex;
	justify-content: center;
	// margin: 5em 20em 5em auto;
	// padding-top: 3.5em;
	padding-bottom: 2em;
	margin: 5em 52em 5em auto;

	@media only screen and ${breakpoint.device.xlg} {
		margin: 6em 18em 5em 8em;
		width: 30%;
	}

	@media only screen and ${breakpoint.device.lg} {
		margin: 6em 18em 5em 8em;
		// margin: 5em 18em 5em 8em;fixing
		width: 30%;
	}

	@media only screen and ${breakpoint.device.l} {
		margin: 6em 18em 5em 8em;
		width: 35%;
	}
	@media only screen and ${breakpoint.device.m} {
		margin: 3em 18em 5em 8em;
		width: 33%;
		padding-top: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		width: 73%;
		// margin: 3em 0 2em 2em;
		// padding-top: 0;
		margin: 4em 0px 4em 2em;
		padding: 0px;
	}

	@media only screen and ${breakpoint.device.xs} {
		width: 67%;
		margin: 2em 0 2em 2em;
		padding-top: 0;
	}
`;


export const Title = styled.h2`
	// font-family: 'Tomorrow';
	font-style: normal;
	font-weight: 400;
	font-size: 50px;
	text-align: left;

	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}


	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 25px;
	}
`;

