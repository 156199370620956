import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 63%;
	margin: 10em 0em 23em 20em;

	@media only screen and ${breakpoint.device.m} {
		width: 70%;
		margin: 10em 7em 12em 7em;
	}

	@media only screen and ${breakpoint.device.s} {
		width: 81%;
		margin: 10em 3em 12em 3em;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 88%;
		margin: 4em 2em 8em 1.5em;
	}
`;


export const Title = styled.p`
	weight: 300;
	color: #fff;
	font-size: 30px;
	margin: 0.5em;

	@media only screen and ${breakpoint.device.m} {
		font-size: 24px;
		margin: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		margin: 0;
	}
`;

export const SubTitle = styled.h2`
	// font-family: 'Tomorrow';
	font-size: 40px;
	weight: 400;
	

	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

