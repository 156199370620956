/* eslint-disable no-unused-vars */
import {
	TextContainer,
	Text,
	Title,
    SubTitle,
    ContactLink,
	TextPrivacy,
} from '../../../styles/PrivacyPolicy/TextPrivacyPolicyStyles';
import { useTranslation } from 'react-i18next';

const TextPrivacyPolicy = () => {
	const [t] = useTranslation();
  return (
		<TextContainer>
			<Text>
				<Title>{t('privacypolicy.text1')}</Title>
				<SubTitle> {t('privacypolicy.text2')} </SubTitle>
				<br></br>

				<Title> {t('privacypolicy.text3')} </Title>
				<TextPrivacy> {t('privacypolicy.text4')} </TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text5')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text6')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text7')}</TextPrivacy>
				<Title> {t('privacypolicy.text8')} </Title>
				<TextPrivacy> {t('privacypolicy.text9')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text10')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text11')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text12')}</TextPrivacy>
				<Title> {t('privacypolicy.text13')} </Title>
				<TextPrivacy> {t('privacypolicy.text14')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text15')}</TextPrivacy>
				<br></br>
				<TextPrivacy> {t('privacypolicy.text16')}</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					{t('privacypolicy.text17')}
					<ContactLink href='mailto: contact@liberada.io'>
						{' '}
						contact@liberada.io.
					</ContactLink>
				</TextPrivacy>
				<br></br>
				<SubTitle>{t('privacypolicy.text18')}</SubTitle>
			</Text>

			{/* <Text>
				<Title>Privacy Policy for Liberada Last updated: 2023 07 17</Title>
				<SubTitle>
					{' '}
					Thank you for choosing Liberada, a web3 NFT books marketplace. At
					Liberada, we are committed to protecting your privacy and ensuring the
					security of your personal information. This Privacy Policy outlines
					how we collect, use, disclose, and safeguard your information when you
					use our platform. By using Liberada, you consent to the practices
					described in this Privacy Policy.{' '}
				</SubTitle>
				<br></br>

				<Title> Information We Collect </Title>
				<TextPrivacy>
					{' '}
					1.1 Personal Information: When you sign up for an account on Liberada,
					we may collect personal information that can be used to identify you,
					such as your name, email address, and contact details. We only collect
					this information when you voluntarily provide it to us.{' '}
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					1.2 Transaction Information: When you buy or sell NFT books on
					Liberada, we collect transaction information, including the details of
					the books, the purchase or sale price, and any relevant information
					needed to complete the transaction.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					1.3 Usage Information: We may collect information about how you use
					Liberada, including your browsing activities, search queries, IP
					address, device information, and other usage data. This information
					helps us analyze user behavior, improve our platform, and provide a
					better user experience.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					1.4 Cookies and Similar Technologies: Liberada may use cookies and
					similar technologies to collect information about your interactions
					with our platform. These technologies enable us to personalize your
					experience, remember your preferences, and provide targeted
					advertisements. You have the option to disable cookies in your browser
					settings, but please note that some features of Liberada may not
					function properly without them.
				</TextPrivacy>
				<Title> How We Use Your Information </Title>
				<TextPrivacy>
					{' '}
					2.1 To Provide and Improve Liberada: We use the information we collect
					to operate, maintain, and enhance our platform. This includes
					facilitating transactions, verifying user identity, responding to user
					inquiries, and customizing your user experience.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					2.2 Communication: We may use your contact information to send you
					administrative messages, updates, and marketing communications related
					to Liberada. You have the option to opt out of receiving promotional
					emails, but you will still receive important transactional
					communications.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					2.3 Legal Compliance: We may use your information to comply with
					applicable laws, regulations, legal processes, or enforceable
					governmental requests, such as tax obligations or fraud prevention.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					2.4 Aggregated Data: We may anonymize and aggregate your information
					with other users&apos;s data to generate statistical or analytical
					insights. This aggregated data will not personally identify you and
					may be used for various purposes, including research, marketing, and
					improving our services.
				</TextPrivacy>
				<Title> How We Share Your Information </Title>
				<TextPrivacy>
					{' '}
					3.1 Service Providers: We may engage third-party service providers to
					perform various functions on our behalf, such as payment processing,
					data storage, analytics, and customer support. These service providers
					have access to your information only to the extent necessary to
					perform their functions and are obligated to protect your data.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					3.2 Business Transfers: If Liberada undergoes a merger, acquisition,
					or sale of all or a portion of its assets, your information may be
					transferred as part of the transaction. We will notify you of any such
					change in ownership or control of your information.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					3.3 Legal Requirements: We may disclose your information if required
					by law, subpoena, or other legal process,or if we have a good-faith
					belief that such disclosure is necessary to protect our rights,
					investigate fraud, respond to security incidents, or comply with a
					judicial proceeding or regulatory request.
				</TextPrivacy>
				<br></br>
				<TextPrivacy>
					{' '}
					3.4 Consent: We may share your information with third parties with
					your consent, which you can provide through the Liberada platform.
					Data Security We take reasonable measures to protect your information
					from unauthorized access, loss, misuse, or alteration. We implement
					industry-standard security measures, such as encryption, firewalls,
					and secure socket layer technology (SSL), to safeguard your data.
					However, no method of transmission over the internet or electronic
					storage is completely secure, and we cannot guarantee absolute
					security. Children&apos;s Privacy Liberada is not intended for use by
					individuals under the age of 18. We do not knowingly collect personal
					information from children. If we become aware that we have
					inadvertently collected personal information from a child, we will
					take steps to delete such information from our records. Changes to
					this Privacy Policy We may update this Privacy Policy from time to
					time to reflect changes in our practices or legal requirements. We
					will notify you of any material changes by posting the updated Privacy
					Policy on the Liberada platform or by other means of communication. We
					encourage you to review this Privacy Policy periodically for the most
					up-to-date information. Contact Us If you have any questions,
					concerns, or requests regarding this Privacy Policy or our privacy
					practices, please contact us at
					<ContactLink href='mailto: contact@liberada.io'>
						{' '}
						contact@liberada.io.
					</ContactLink>
				</TextPrivacy>
				<br></br>
				<SubTitle>
					By using Liberada, you acknowledge that you have read and understood
					this Privacy Policy and agree to the collection, use, and disclosure
					of your information as described herein.
				</SubTitle>
			</Text> */}
		</TextContainer>
	);
}

export default TextPrivacyPolicy