/* eslint-disable no-unused-vars */
import { Title, 
	SubTitle, 
	TextContainer,
	} from '../../../styles/AboutUs/TextAbout3Styles'
import { useTranslation } from 'react-i18next';
const TextAbout3 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				<span>{t('about.text5')}</span> {t('about.text6')}
			</Title>
			<SubTitle>{t('about.text7')}</SubTitle>
			{/* <Title><span>Adding value</span> to the writers</Title>
			<SubTitle>
				In Liberada, readers are the owners of the NFT and get free tokens when
				they buy them to participate in voting on different aspects of the
				ecosystem. But keeping in mind that the copyright remains with the
				writer.
			</SubTitle> */}
		</TextContainer>
	);
};

export default TextAbout3;
