import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;

	@media only screen and ${breakpoint.device.lg} {
		width: 100%;
		margin: auto;
	}

	@media only screen and ${breakpoint.device.l} {
		width: 100%;
		margin: auto;
	}
	@media only screen and ${breakpoint.device.m} {
		margin: auto;
		width: 100%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		width: 100%;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 100%;
		margin: auto;
	}
`;

export const Title = styled.h1`
	padding-left: 0.5em;
	margin: 5em 1em 2em 1em;
	width: 60%;
	text-align: center;

	span {
		color: #fff;


		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and ${breakpoint.device.lg} {
		padding: 0;
		margin: 3.5em 1em 1em 3em;
		// margin: 4em 1em 4em 1em;
		width: 72%;
		text-align: left;
	}
	@media only screen and ${breakpoint.device.l} {
		padding: 0;
		margin: 4.3em 1em 4em 1em;
		width: 75%;
	}
	@media only screen and ${breakpoint.device.m} {
		padding-left: 1em;
		margin: 4em 1em 4em 1em;
		width: 67%;
		font-size: 60px;
		text-align: left;
	}
	@media only screen and ${breakpoint.device.s} {
		padding: 0;
		margin: 7em 1em 4em 2em;
		width: 90%;
		font-size: 40px;
		text-align: left;
	}
	@media only screen and ${breakpoint.device.xs} {
		// padding: 0;
		width: 80%;
		margin: 6em 1em 2em 1em;
		padding-top: 1em;
		// width: 100%;
		// margin: 6em 1em 1em 1em;
		font-size: 35px;
		text-align: left;
	}
`;
