import GlobalStyles from './styles/GlobalStyles';
import AnimatedRoutes from './components/AnimatedRoutes'



const App = () => {
	return (
		<>
			<GlobalStyles />
			<AnimatedRoutes />
		</>
	);
};

export default App;



