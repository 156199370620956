/* eslint-disable no-unused-vars */
import {
	MarketplaceSection,
	MarketplaceBg,

	// 	Content,
	// 	Title,
	//   TitleWrapper,
	// 	SubText,
	// 	Items,
	// 	ContainerFilters,
	//   Filters,
} from '../../styles/Marketplace/MarketplaceStyles';
import NavbarBlack from '../NavbarBlack';
import MarketplaceGrid from '../MarketplaceGrid';

// import MarketplaceFullBg from '../../assets/images/MarketplaceFullBg.svg';
import DetailCard from '../DetailCard';
//  import Footer from '../Footer'
import { useState, useEffect } from 'react';
// import mobileImage from '../../assets/images/AboutUsMobile.svg';
import CountDownTimer from '../CountDownTimer/CountDownTimer';
import dayjs from 'dayjs';
//  import { Books, updateSoldData } from '../../Data';

const Marketplace = () => {
	const [showDetailCard, setShowDetailCard] = useState(false);
	const [selectedItem, setSelectedItem] = useState();
	const [countdownFinished, setCountdownFinished] = useState(false);
	//    const [updatedBooks, setUpdatedBooks] = useState([]);

	//  useEffect(() => {
	// 		async function fetchData() {
	// 			await updateSoldData();

	// 			const updatedBookData = [...Books]; // This should reflect the updated data after calling updateSoldData

	// 			setUpdatedBooks(updatedBookData);

	// 			// If a selected item exists, update its data with the latest information
	// 			if (selectedItem) {
	// 				const updatedItem = updatedBookData.find(
	// 					book => book.policy === selectedItem.policy
	// 				);
	// 				setSelectedItem(updatedItem);

	// 			}
	// 		}
	// 		fetchData();
	//  }, []);

	const handleMoreInfoClick = item => {
		// Set the selected item using the updated data
		// setSelectedItem(updatedBooks.find(book => book.policy === item.policy));
		setSelectedItem(item);
	};

	const handleCloseDetailCard = () => {
		setSelectedItem(null);
		setShowDetailCard(false);
	};

	// ---CONFIGURATION FOR COUNTDOWN---
	// Specify the exact date until release in a format that dayjs can parse.
	// const releaseDateStr = '2023-08-15 12:00:00';
	// const releaseDateStr = '2023-08-15 12:00:00'; // Replace this with your desired release date and time.
	// const releaseDate = dayjs(releaseDateStr).valueOf();

	// useEffect(() => {
	// 	const now = dayjs().valueOf();
	// 	if (now >= releaseDate) {
	// 		// Countdown has finished
	// 		setCountdownFinished(true);
	// 	} else {
	// 		const intervalId = setInterval(() => {
	// 			const currentTime = dayjs().valueOf();
	// 			if (currentTime >= releaseDate) {
	// 				setCountdownFinished(true);
	// 				clearInterval(intervalId);
	// 			}
	// 		}, 1000);
	// 		return () => clearInterval(intervalId);
	// 	}
	// }, [releaseDate]);

	// ---CONFIGURATION FOR COUNTDOWN---

	return (
		<>
			<MarketplaceSection
				// style={{
				// 	backgroundImage: `url(${MarketplaceFullBg})`,
				// }}
			>
				{/* <MarketplaceBg
					style={
						{
							// backgroundImage: `url(${MarketplaceFullBg})`,
						}
					}
				> */}
					<NavbarBlack />
					{/* to get timestamp days in milisecs-> current timestamp + days until release(1690316099728 + 1728000000 = 1692044099728 /// get days remaining for example 20days (20 * 24 * 60 * 60 * 1000 = 1728000000)) */}
					

					
						<MarketplaceGrid handleMoreInfoClick={handleMoreInfoClick} />
				
					{/* // ---CONFIGURATION FOR COUNTDOWN--- */}	
					{/* {countdownFinished ? (
						// Show the MarketplaceGridCopy when the countdown is finished
						<MarketplaceGrid handleMoreInfoClick={handleMoreInfoClick} />
					) : (
						// Render the CountDownTimer
						<CountDownTimer countdownTimestampsMs={releaseDate} />
					)} */}
					{/* // ---CONFIGURATION FOR COUNTDOWN--- */}

					{selectedItem && (
						<DetailCard onClose={handleCloseDetailCard} item={selectedItem} />
					)}
				{/* </MarketplaceBg> */}
				{/* <Footer /> */}
			</MarketplaceSection>
		</>
	);
};

export default Marketplace;
