/* eslint-disable no-unused-vars */
import { Title, SubTitle, 
	 TextContainer} from '../../../styles/AboutUs/TextAbout5Styles'
import { useTranslation } from 'react-i18next';

const TextAbout5 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				{t('about.text10')} <span>{t('about.text11')}</span>
			</Title>
			<SubTitle>{t('about.text12')}</SubTitle>
			{/* <Title>
				Liberada <span>guarantees the copyright</span>
			</Title>
			<SubTitle>
				Writers can stamp their authorship through NFTs on the Cardano network,
				are free to create multiple NFTs for their works, and can sell them on
				our marketplace or other Cardano marketplaces.
			</SubTitle> */}
		</TextContainer>
	);
};

export default TextAbout5;
