import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 50%;
    margin: 19em 28em 19em 28em;


	@media only screen and ${breakpoint.device.lg} {
		margin: 11em 7em 11em 7em;
		// width: 70%;
		width: 80%;
	}
	@media only screen and ${breakpoint.device.l} {
		margin: 11em 7em 1em 7em;
		width: 70%;
	}

	@media only screen and ${breakpoint.device.m} {
		margin: 7em 1em 7em 1em;
		width: 95%;
	}

	@media only screen and ${breakpoint.device.s} {
		width: 83%;
		margin: 10em 2em 13em 2em;
	}

	@media only screen and ${breakpoint.device.xs} {
		width: 90%;
		margin: 12em 0em 1em 1em;
	}
`;


export const Title = styled.p`
	// font-family: 'Roboto', sans-serif;
	font-weight: 300;
	color: #fff;
	font-size: 30px;
	margin: 20px;

	@media only screen and ${breakpoint.device.m} {
		font-size: 24px;
		margin: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		margin: 0;
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 20px;
		margin: 0;
	}
`;

export const SubTitle = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	font-size: 40px;
	weight: 400;
	// margin: 20px;

	@media only screen and ${breakpoint.device.l} {
		margin: 10px auto;
		font-size: 40px;
	}
	@media only screen and ${breakpoint.device.m} {
		margin: 10px auto;
		font-size: 35px;
		width: 68%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 10px auto;
		font-size: 30px;
		width: 100%;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin: 10px auto;
		font-size: 30px;
		width: 100%;
	}
`;

export const SubText = styled.p`
	margin: auto;
	// width: 40%;
	width: 50%;
	margin-top: 2em;
	font-size: 18px;
	font-weight: 300;

	@media only screen and ${breakpoint.device.l} {
		width: 45%;
		margin: 1em auto;
		font-size: 18px;
	}
	@media only screen and ${breakpoint.device.m} {
		width: 50%;
		margin: 1em auto;
		font-size: 16px;
	}
	@media only screen and ${breakpoint.device.s} {
		width: 80%;
		margin: 1em auto;
		font-size: 14px;
	}
`;
