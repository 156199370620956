import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';



export const TextContainer = styled.div`
	width: 50%;

	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 3rem;
	margin: auto;
	justify-content: center;
	justify-items: center;

	@media only screen and ${breakpoint.device.lg} {
		width: 80%;
	}
	@media only screen and ${breakpoint.device.l} {
		width: 80%;
	}
	@media only screen and ${breakpoint.device.m} {
		width: 85%;
	}

	@media only screen and ${breakpoint.device.s} {
		display: grid;
		grid-template-columns: 1fr;
		// width: 50%;
		grid-gap: 1rem;
		margin: auto;
		align-content: center;
		justify-content: center;
		justify-items: center;
	}
`;

export const TextBox = styled.div`
	width: 80%;
	align-items: center;
`;

export const Text = styled.div`
	font-family: 'Roboto';
	font-weight: 300;
	font-size: 16px;
	color: #fff;
	margin: auto;
	width: 100%;
	// padding: 1em;
	text-align: center;

	span {
		color: #64dcff;
	}
	a {
		margin: 0.5em;
		
		&:hover {
			color: #64dcff;
		}
	}

	@media only screen and ${breakpoint.device.s} {
		width: 80%;
	}
`;