import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';
import { Link } from 'react-router-dom';


export const TextContainer = styled.div`
	width: 35%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 5em 0 18em 20em;
	padding-top: 3em;

	@media only screen and ${breakpoint.device.m} {
		padding: 0;
		margin: 5em 16em 20em 16em;
		width: 34%;
	}
	@media only screen and ${breakpoint.device.s} {
		margin: 5em 0 2em 7em;
		width: 64%;
	}

	@media only screen and ${breakpoint.device.xs} {
		padding: 0;
		margin: 10em 0 2em 5em;
		width: 66%;
	}
`;

export const Title = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	font-style: normal;
	weight: 400;

	font-size: 50px;

	margin-left: 0.2em;


	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

export const SubTitle = styled.p`
	font-family: 'Roboto'
	font-weight: 300;
	font-size: 18px;
	 width: 100%;
	
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	margin: 10px;


	@media only screen and ${breakpoint.device.m} {
		font-size: 16px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
export const MarketplaceLink = styled(Link)`
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	letter-spacing: 0.1em;
	color: #fff;
	margin: 10px;

	&:hover {
		text-decoration: underline;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 14px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
