import { createGlobalStyle } from 'styled-components';
import '@fontsource/tomorrow';
import '@fontsource/roboto';

const GlobalStyles = createGlobalStyle`
*,*::before,*::after {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: "Tomorrow", sans-serif; 
     font-style: normal;
    font-weight: 400;
    font-size: 75px;
}

h2 {
   font-family: "Tomorrow", sans-serif;
   font-style: normal;
    font-weight: 400;
font-size: 25px;
}




p{
// font-family: "Roboto", sans-serif;
font-style: normal;
font-weight: 300;
}

a { 
    // font-family: "Roboto", sans-serif;
    // font-weight: 300;
    color: inherit;
    text-decoration: none;
}
`;

export default GlobalStyles;
