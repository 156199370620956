/* eslint-disable no-unused-vars */
import {
	FooterSection,
	FooterContainer,
	AboutSection,
	MainSection,
	Text,
	Copyright,
	Items,
	List,
	Links,
	PrivacyLink,
} from '../styles/FooterStyles';
import whitepaperENG from '../assets/Whitepaper/Liberada_Whitepaper_ENG.pdf';
import whitepaperESP from '../assets/Whitepaper/Liberada_Whitepaper_ESP.pdf';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const [t ] = useTranslation();
  return (
		<FooterSection>
			<FooterContainer>
				<AboutSection>
					<Text>{t('footer.text1')}</Text>
					{/* <Text>
						Liberada unite literature and technology to improve your experience
						as a writer and reader.
					</Text> */}
				</AboutSection>
				<MainSection>
					<Items>
						<List>
							<Links
								src='instagramLiberada'
								href='https://instagram.com/liberadanft?igshid=YmMyMTA2M2Y='
								target='_blank'
								rel='noreferrer'
							>
								Instagram
							</Links>
						</List>
						<List>
							<Links
								src='facebookLiberada'
								href='https://www.facebook.com/people/NFT-Books-Liberada/100090957735416/'
								target='_blank'
								rel='noreferrer'
							>
								Facebook
							</Links>
						</List>
						<List>
							<Links
								src='twitterLiberada'
								href='https://twitter.com/LiberadaNFT'
								target='_blank'
								rel='noreferrer'
							>
								Twitter
							</Links>
						</List>
						<List>
							<Links
								src='linkedInLiberada'
								href='https://es.linkedin.com/company/liberada?trk=public_profile_topcard-current-company'
								target='_blank'
								rel='noreferrer'
							>
								LinkedIn
							</Links>
						</List>
					</Items>
				</MainSection>
				<MainSection>
					<Items>
						<List>
							Whitepaper:{' '}
							<span>
								<Links href={whitepaperENG} download='Liberada_Whitepaper_ENG'>
									{' '}
									ENG{' '}
								</Links>{' '}
								/{' '}
								<Links href={whitepaperESP} download='Liberada_Whitepaper_ESP'>
									{' '}
									ESP{' '}
								</Links>
							</span>
						</List>
						<List>
							<PrivacyLink to='/privacypolicy'> {t('footer.text2')}</PrivacyLink>
						</List>
					</Items>
				</MainSection>
				<MainSection>
					<Items>
						<List>
							<Links href='mailto: contact@liberada.io'>
								contact@liberada.io
							</Links>
						</List>
					</Items>
				</MainSection>
			</FooterContainer>
			<Copyright>
				{t('footer.text3')}
				<br></br>
				<span>{t('footer.text4')}</span>
			</Copyright>
		</FooterSection>
	);
}; 
export default Footer;

