/* eslint-disable no-unused-vars */
import { Title, 

	TextContainer} from '../../../styles/AboutUs/TextAboutStyles';
import { useTranslation } from "react-i18next"


const TextAbout = () => {
	const [t] = useTranslation()
	return (
		<TextContainer>
			<Title>
				<span>{t('about.text1')}</span> {t('about.text2')}
			</Title>
		</TextContainer>
		// <TextContainer>

		// 		<Title>
		// 			<span>Literature and technology</span> unite to enhance your writing
		// 			and reading journey!
		// 		</Title>

		// </TextContainer>
	);
};

export default TextAbout;
