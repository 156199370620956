import styled from 'styled-components';


export const AuthorsSection = styled.section`
	
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	
`;

