import styled from 'styled-components';

import breakpoint from './Variables/MediaQ';
import { motion } from 'framer-motion';

export const ModalView = styled(motion.div)`
	position: fixed;
	height: 100vh;
	width: 100vw;	
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.01);
	backdrop-filter: blur(4px);
`;

export const Container = styled(motion.div)`
	position: fixed;
	background: #fff;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	text-align: center;

	overflow: hidden;
	max-height: 706px;
	max-width: 1400px;

	@media only screen and ${breakpoint.device.lg} {
		max-height: 600px;
		max-width: 1200px;

		margin-top: 6em;
		margin-bottom: 1em;
		padding: 0;
	}

	@media only screen and ${breakpoint.device.m} {
		max-height: 900px;
		max-width: 790px;
		margin: 5em auto;
	}

	@media only screen and ${breakpoint.device.s} {
		max-height: 900px;
		max-width: 360px;

		margin-top: 3.5em;
		margin-bottom: 0.5em;
	}

	@media only screen and ${breakpoint.device.xs} {
		max-width: 350px;
		margin: 3.5em auto;
		margin-bottom: .5em;	
		
	}
`;
export const BookDetails = styled(motion.div)`
	position: relative;
	text-align: left;
	overflow: hidden;

	float: right;
	width: 50%;
	background-color: #fff;
   
	margin: auto;


	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		width: 100%;
	
		position: absolute;
		right: 0;
		left: 0;
		top: 360px;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin: auto;
		width: 100%;

		position: absolute;
		right: 0;
		left: 0;
		top: 340px;
	}
`;
export const Title = styled(motion.h2)`
	margin: 2em 0.85em 0;
	// font-family: 'Tomorrow';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;

	@media only screen and ${breakpoint.device.m} {
		margin: 1em 1em 0;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 24px;
		width: 90%;
		margin: auto;	
	}

	@media only screen and ${breakpoint.device.xs} {
		font-size: 15px;
		width: 100%;

		margin: 0.2em 0.5em 0;
	}
`;

export const Author = styled(motion.p)`
	color: #000;
	// font-family: 'Roboto';
	font-size: 20px;
	font-weight: 300;
	margin: 0em 1em 0;

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		width: 100%;
		margin: 0em .9em 0;
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 12px;
		width: 95%;
		margin: 0.2em auto;
	}
`;
export const Text = styled(motion.p)`
	margin: 1em 1em 2em;
	// font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	font-size: 20px;


	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
		width: 90%;
		margin: 0.5em auto;
		
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 12px;
		width: 95%;
		margin: 0.2em auto;
	}
`;

export const TotalSupply = styled(motion.p)`
	margin: 1em 1em 0;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;

	color: #a1b2bc;

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		margin: 0;
		padding-left: 0.8em;
	}

	@media only screen and ${breakpoint.device.xs} {
		width: 95%;
		font-size: 15px;
		margin: 0 auto;
		padding: 0;
	}
`;

// export const PriceContainer = styled(motion.div)`
// //  display: flex;
// //  flex-direction: row;
// //  justify-content: flex-end;
// //  margin-top: 1.5em;


// `
export const Price = styled(motion.h1)`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 0px;
	right: 170px;

	text-transform: uppercase;
	color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		bottom: 0px;
		right: 260px;

		margin-bottom: 0.5em;
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 20px;
		bottom: 0px;
		right: 250px;
		margin-bottom: 0.5em;
	
	}
`;



export const BuyBtn = styled(motion.a)`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 0px;
	right: 25px;

	text-transform: uppercase;
	color: #64dcff;
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		
	}

	// @media only screen and ${breakpoint.device.xs} {
	// 	font-size: 12px;
	// 	bottom: 5px;
	// }
`;

export const JpegBtn = styled(motion.a)`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 0px;
	right: 25px;

	text-transform: uppercase;
	color: #64dcff;
	font-size: 24px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		// right: 0;
	}
	// @media only screen and ${breakpoint.device.xs} {
	// 	font-size: 12px;
	// 	bottom: 5px;
	// }
`;


export const Close = styled(motion.a)`
	position: absolute;
	top: 0;
	right: 15px;
	z-index: 99;

	font-family: Tomorrow;
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;

	margin: 0.2em;
	padding: 0.2em;

	text-transform: uppercase;
	color: #000;

	@media only screen and ${breakpoint.device.s} {
		right: 10px;
		top: 310px;
		padding: 0;
		color: #fff;
	}

	@media only screen and ${breakpoint.device.xs} {
		right: 10px;
		top: 300px;
		padding: 0;
		color: #fff;
	}
`;

export const ImageBook = styled(motion.img)`
	display: inline-block;
	position: absolute;
	overflow: hidden;
	height: auto;
	right: 0;
	left: 0;
	width: 50%;
	object-fit: cover;
	height: 100%;

	

	@media only screen and ${breakpoint.device.s} {
		// width: 100%;
		// max-height: 42%;
		// object-position: top;

		background-position: center;
		background-size: cover;
		height: 350px;
		width: 100%;
	}
	@media only screen and ${breakpoint.device.xs} {
		background-position: center;
		background-size: cover;
		max-height: 335px;
		width: 100%;
	}
`;

// ---BADGE----

export const Ribbon = styled(motion.div)`
	width: 200px;
	height: 200px;
	overflow: hidden;
	position: absolute;
	top: 0px;
	right: -10px;
`;

// export const RibbonGold = styled.div`
// 	left: -25px;
// 	top: 30px;
// 	transform: rotate(45deg);
// 	position: absolute;
// 	display: block;
// 	width: 300px;
// 	padding: 10px 0;
// 	// background-color: #00aaa0;
// 	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
// 	color: #fff;
// 	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
// 	text-transform: uppercase;
// 	text-align: center;
// 	// border: 2px dotted #fff;
// 	// outline: 5px solid #f5cd79;

// 	font-family: 'Tomorrow', sans-serif;
// 	font-size: 20px;
// 	font-style: normal;
// 	font-weight: 400;

// 	background: radial-gradient(
// 			ellipse farthest-corner at right bottom,
// 			#fedb37 0%,
// 			#fdb931 8%,
// 			#9f7928 30%,
// 			#8a6e2f 40%,
// 			transparent 80%
// 		),
// 		radial-gradient(
// 			ellipse farthest-corner at left top,
// 			#ffffff 0%,
// 			#ffffac 8%,
// 			#d1b464 25%,
// 			#5d4a1f 62.5%,
// 			#5d4a1f 100%
// 		);
// `;

export const RibbonSoldOut = styled.div`
	left: -25px;
	top: 30px;
	transform: rotate(45deg);
	position: absolute;
	display: block;
	width: 300px;
	padding: 10px 0;
	// background-color: #00aaa0;
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
	color: #000;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;
	// border: 2px dotted #fff;
	// outline: 5px solid #f5cd79;

	font-family: 'Tomorrow', sans-serif;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;

	background: rgb(134 227 254 / 67%);
	backdrop-filter: blur(2px);
`;

export const CardPrice = styled.div`
	position: absolute;
	background: rgb(134 227 254 / 67%);
	backdrop-filter: blur(2px);
	bottom: 0;
	right: 50%;
	left: 0;

	h2 {
		font-weight: 400;
		font-size: 24px;
		text-align: right;
		margin-right: 1em;
	}

	@media only screen and ${breakpoint.device.s} {
		left: 0;
		right: 0;
		top: 45%;
		bottom: 50%;
	}
`;


