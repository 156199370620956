 import { useState, useEffect } from 'react';
import NavbarWhite from '../NavbarWhite';
import Footer from '../Footer';

import ReadersFullBg from '../../assets/images/ReadersFullBg.svg'
import mobileImage from '../../assets/images/ReadersMobile.svg'
import { ReadersSection } from '../../styles/Readers/ReadersStyles';

import TextReaders from '../textComponents/Readers/TextReaders';
import TextReaders2 from '../textComponents/Readers/TextReaders2';
import TextReaders3 from '../textComponents/Readers/TextReaders3';
import TextReaders4 from '../textComponents/Readers/TextReaders4';
import TextReaders5 from '../textComponents/Readers/TextReaders5';


const Readers = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const imageUrl = windowWidth >= 431 ? ReadersFullBg : mobileImage;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<>
			<ReadersSection
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
			>
				<NavbarWhite />
				<TextReaders />
				<TextReaders2 />
				<TextReaders3 />
				<TextReaders4 />
				<TextReaders5 />
			</ReadersSection>
			<Footer />
		</>
	);
};

export default Readers;
