/* eslint-disable no-unused-vars */
import { Title, 
	 TextContainer } from '../../../styles/Authors/TextAuthorsStyles';
import { useTranslation } from 'react-i18next';

const TextAuthors = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				<span>
					<a
						href='https://docs.google.com/forms/d/1gv9Apm2j44tC7fIedwusURgv_y_vNoxbf58d8ai-YlM/edit'
						target='_blank'
						rel='noreferrer'
						src='liberadaform'
					>
						{t('authors.text1')}{' '}
					</a>
				</span>
				{t('authors.text2')}
				<br></br> {t('authors.text3')}
			</Title>
		</TextContainer>
	);
};

export default TextAuthors;
