import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		// width: 80%;
	}
`;

export const Title = styled.h2`
	// font-family: 'Tomorrow';
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	text-align: center;
	width: 53%;
	margin: 0 0 16em 0;

	span {
		color: #fff;
         cursor: pointer;
		a {
			text-decoration: none;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and ${breakpoint.device.lg} {
		font-size: 30px;
		width: 76%;
		margin-bottom: 15em;
	}
	@media only screen and ${breakpoint.device.l} {
		font-size: 30px;
		width: 76%;
		margin-bottom: 22em;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 30px;
		width: 70%;
		margin: 5em 0 12em 0;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		width: 100%;
		margin-bottom: 10em;
	}
	@media only screen and ${breakpoint.device.xs} {
		font-size: 20px;
		width: 62%;
		// margin-bottom: 10em;
		margin: 5em auto;
		padding-bottom: 3em;
	}
`;

export const SocialLinks = styled.div`
	position: absolute;

	bottom: 75%;
	display: flex;
	gap: 2em;

	a {
		color: #fff;
		text-decoration: none;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;

		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and ${breakpoint.device.m} {
		gap: 1em;
		bottom: 52%;
	}

	@media only screen and ${breakpoint.device.s} {
		gap: 1em;
		bottom: 45%;
	}

	@media only screen and ${breakpoint.device.xs} {
		gap: 1em;
		bottom: 32%;
	}
`;