import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';

export const Container = styled.div`

	max-width: 1728px;

	margin: 0 auto;
	padding: 20px;
`;

// export const Header = styled.div`
// 	margin: 1em;
//     text-align: start;
// 	items-align: left;
// 	color: #fff;

// 	h2 {

// 		font-size: 35px;
// 	}
// `;

export const CardGrid = styled.div`
	margin-top: 2rem;
	margin-bottom: 2rem;
	display: grid;
	// grid-template-columns: repeat(6, 1fr);
	grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));

	grid-gap: 1em;
	justify-content: center;
	align-content: center;

	@media only screen and ${breakpoint.device.s} {
		grid-template-columns: repeat(2, 1fr);
	}
`;
