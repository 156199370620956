/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
	ContainerBullets,
	ContainerInfo,
	Card,
	Container,
	Ribbon,
	RibbonSoldOut,
	RibbonGold,
	ShortTitle,
	Author,
	Rarity,
	TotalSupply,
	PriceBuyContainer,
	Price,
	BuyBtn,
	JpegBtn,
} from '../styles/Marketplace/MarketplaceCardStyles';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
 import { useTranslation } from 'react-i18next';


const MarketplaceCard = ({
	handleMoreInfoClick,
	id,
	image,
	price,
	typeBadge,
	totalSupply,
	totalSupplyTitle,
	singleRelease,
	shortTitle,
	author,
	sold,
	buynow,
	jpegstore,
	jpegstorelink,
}) => {
	const [t, i18n] = useTranslation('global');

	const isSoldOut = parseFloat(sold) >= parseFloat(totalSupply);
	const uniqueRelease = typeBadge;

	const [soldData, setSoldData] = useState( sold || '0');

	// const supplyData = parseFloat(totalSupply) - parseFloat(sold);

	useEffect(() => {
		setSoldData( sold || '0'); // Update the soldData state whenever the "sold" prop changes
	}, [sold]);

	return (
		<>
			<Container>
				<Link onClick={handleMoreInfoClick}>
					<Card className='stacked' key={id} title='Click for more details'>
						<img src={`../Books/${image}`} alt={`${author}`} />
						<Ribbon>
							{isSoldOut && !uniqueRelease ? (
								<RibbonSoldOut> {t('card.text5')} </RibbonSoldOut>
							) : (
								''
							)}
						</Ribbon>
					</Card>
				</Link>
				<ContainerBullets>
					{/* <span>
						<i className='fas fa-comment-alt'></i> ....
					</span> */}
				</ContainerBullets>
				<ContainerInfo>
					<div className='container__profile__text'>
						<ShortTitle>{shortTitle}</ShortTitle>
						<Author>{author}</Author>

						{/* <Rarity>Rarity: 15%</Rarity> */}
						<TotalSupply>
							{totalSupplyTitle} {sold} / {totalSupply}
						</TotalSupply>

						{isSoldOut && !uniqueRelease ? (
							<Price>{jpegstore}</Price>
						) : (
							<Price>{price}</Price>
						)}
						{isSoldOut && !uniqueRelease ? (
							<JpegBtn
								href={`${jpegstorelink}`}
								target='_blank'
								rel='noreferrer'
							>
								JPEG.STORE
							</JpegBtn>

						) : (
							<BuyBtn href={`${buynow}`} target='_blank' rel='noreferrer'>
								{t('card.text1')}
							</BuyBtn>
						)}
					</div>
				</ContainerInfo>
			</Container>
		</>
	);
};

export default MarketplaceCard;


