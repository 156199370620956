import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';

export const TextContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Text = styled.div`
	max-width: 54%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 10em 2em 29em 2em;

	@media only screen and ${breakpoint.device.m} {
		margin: 3em 1em 20em 1em;
		max-width: 85%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 3em 1em 22em 1em;
		max-width: 85%;
	}

	@media only screen and ${breakpoint.device.xs} {
		margin: 3em 1em 22em 1em;
		max-width: 85%;
	}
`;

export const Title = styled.h2`
	margin: 1em auto;
	width: 100%;
	text-align: left;
	color: #fff;
	font-size: 20px;


	
`;
export const SubTitle = styled.p`
	margin: 0.5em auto;
	width: 100%
	text-align: left;
	color: #fff;
`;


export const TextPrivacy = styled.p`
width: 100%
	text-align: left;
	color: #fff;
`;

export const ContactLink = styled.a`
	margin: auto;
	width: 100%;
	text-align: left;
	color: #fff;

	&:hover {
		color: #64dcff;
	}
`;


