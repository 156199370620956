


function LogoLiberada (props='React.SVGProps<SVGSVGElement>'){
  return (
		<svg
			width='260'
			height='51'
			viewBox='0 0 260 51'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g clipPath='url(#clip0_1_18)'>
				<path
					d='M36.6157 50.996H42.6696V14.9466H36.6157V51V50.996ZM36.6157 11.0112H42.6696V2.8833H36.6157V11.0152V11.0112Z'
					fill='currentColor'
				/>
				<path
					d='M57.8664 39.0009V27.0099L64.9499 19.7695H74.0247L77.7428 23.5604V42.3782L74.0247 46.169H64.9499L57.8664 39.0009ZM51.8165 0.192749V50.996H57.8704V45.3458L63.3714 50.996H76.645L83.7967 43.7596V22.183L76.645 14.9426H63.3714L57.8704 20.665V0.192749H51.8165Z'
					fill='currentColor'
				/>
				<path
					d='M97.0703 23.5604L100.852 19.7695H111.65L115.432 23.5604V30.3872H97.0703V23.5604ZM98.2402 14.9466L91.0164 22.187V43.7636L98.2402 51.004H120.869V45.9723H100.852L97.0703 42.1814V35.2181H121.486V22.191L114.262 14.9506H98.2402V14.9466Z'
					fill='currentColor'
				/>
				<path
					d='M142.188 14.9466L135.93 21.424V14.9466H129.876V51H135.93V27.7649L142.46 21.0104H151.403V14.9466H142.188Z'
					fill='currentColor'
				/>
				<path
					d='M157.044 42.3822V36.8686L160.826 33.146L176.44 33.0777V39.1415L169.356 46.1731H160.826L157.044 42.3822ZM155.67 14.9466V20.0466H172.726L176.44 23.8375V28.3191H158.142L150.99 35.5594V43.7636L158.142 51.004H170.867L176.44 45.4181V51.004H182.49V22.183L175.338 14.9426H155.67V14.9466Z'
					fill='currentColor'
				/>
				<path
					d='M196.589 42.3822V23.5644L200.371 19.7735H209.45L216.533 27.0139V39.005L209.45 46.1731H200.371L196.589 42.3822ZM216.537 0.192749V20.665L210.964 14.9426H197.759L190.535 22.183V43.7596L197.759 50.996H210.964L216.537 45.3458V50.996H222.587V0.192749H216.537Z'
					fill='currentColor'
				/>
				<path
					d='M234.554 42.3822V36.8686L238.337 33.146L253.946 33.0777V39.1415L246.863 46.1731H238.333L234.55 42.3822H234.554ZM233.18 14.9466V20.0466H250.236L253.95 23.8375V28.3191H235.656L228.505 35.5594V43.7636L235.656 51.004H248.381L253.95 45.4181V51.004H260.004V22.183L252.852 14.9426H233.184L233.18 14.9466Z'
					fill='currentColor'
				/>
				<path d='M6.29026 0H0V45.2053H6.29026V0Z' fill='currentColor' />
				<path
					d='M31.7758 45.2053H6.29028V51H31.7758V45.2053Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1_18'>
					<rect width='260' height='51' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
export default LogoLiberada;
