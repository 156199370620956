import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 5em 55em 6em auto;
	padding-bottom: 1em;

	@media only screen and ${breakpoint.device.xlg} {
		margin: 6em 18em 5em 8em;
		width: 30%;
	}

	@media only screen and ${breakpoint.device.lg} {
		margin: 6em 18em 5em 8em;
		// margin: 5em 18em 5em 8em;fixing
		width: 30%;
	}

	@media only screen and ${breakpoint.device.l} {
		margin: 6em 18em 5em 8em;
		width: 35%;
	}

	@media only screen and ${breakpoint.device.m} {
		margin: 6em 18em 5em 8em;
		width: 33%;
		padding-top: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		margin: 3em 0 9em 2em;
		width: 60%;
	}
`;


export const Title = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	font-style: normal;
	weight: 400;
	font-size: 50px;
	padding-bottom: 0.5em;
	// margin: 10px;
	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

export const SubTitle = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	// margin: 10px;

	@media only screen and ${breakpoint.device.m} {
		font-size: 16px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
