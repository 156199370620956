/* eslint-disable no-unused-vars */
import { Title, 
	TextContainer} from '../../../styles/ContactUs/TextContactUsStyles';
import { useTranslation } from 'react-i18next';
const TextContactUs = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				{t('contactus.text1')} <span>{t('contactus.text2')}</span>{' '}
				{t('contactus.text3')}
			</Title>
			{/* <Title>
				
				 If you want to <span>learn
				about this new world, create innovative strategies, and find new
				solutions, </span> please contact us. We are here to help and would love to hear
				from you.
			</Title> */}
		</TextContainer>
	);
};

export default TextContactUs;
