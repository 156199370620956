import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';

// $bg: #fff;
// $text: #7288a2;
// $gray: #4d5974;
// $lightgray: #e5e5e5;
// $blue: #03b5d2; 

export const FaqSection = styled.section`
	width: 100vw;
	height: 100vh;
`;
export const FaqContainer = styled.div`
	background-color: #64dcff;
	// min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;
export const Container = styled.div`
	max-width: 100%;
	margin: 0 auto;
	padding-bottom: 10em;
	padding-top: 8em;
	width: 50em;

	// max-width: 100%;
	// margin: 5em 0em 0em 32em;
	// /* margin: 0em auto; */
	// padding-bottom: 30em;
	// padding-top: 20em;
	// width: 50em;
	
	@media only screen and ${breakpoint.device.m} {
		width: 40em;
		padding: 5em;
		// margin-top: 5em;
		// padding-bottom: 2em;
		// padding-top: 10em;
	}
	@media only screen and ${breakpoint.device.s} {
		width: 20em;
		padding-bottom: 5em;
		padding-top: 5em;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 17em;
		padding-bottom: 5em;
		padding-top: 5em;
	}
`;
export const Title = styled.h1`
	margin: 0 auto;
	padding-bottom: 1em;
	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 50px;
		padding: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 50px;
		padding: 0;
	}

	@media only screen and ${breakpoint.device.xs} {
		font-size: 35px;
		padding: 0;
	}
`;

export const AccordionWrapper = styled.div`
	.accordion-item {
		border-bottom: 1px solid #000;
	
		// button[aria-expanded='true'] {
		// 	// border-bottom: 1px solid #000;
		// 	// border-bottom: 1px solid ${props => props.theme.blue};
		// }
	}
`;

export const AccordionItem = styled.div`
	button {
		position: relative;
		display: block;
		text-align: left;
		width: 100%;
		padding: 1em 0;
		color: ${props => props.theme.text};
		// font-size: 1.15rem;
		font-weight: 400;
		border: none;
		background: none;
		outline: none;
		&:hover,
		&:focus {
			cursor: pointer;
			color: #fff;
			// color: ${props => props.theme.blue};
			&::after {
				cursor: pointer;
				color: #fff;
				// color: ${props => props.theme.blue};
				border: 1px solid ${props => props.theme.blue};
			}
		}
		.accordion-title {
			padding: 0.5em 1.5em 0.5em 0;
		}
		.icon {
			display: inline-block;
			position: absolute;
			top: 18px;
			right: 0;
			width: 35px;
			height: 35px;
			// border: 1px solid;
			// border-radius: 22px;
			&::before {
				display: block;
				position: absolute;
				content: '';
				top: 12px;
				left: 2px;
				width: 20px;
				height: 1px;
				background: currentColor;
			}
			&::after {
				display: block;
				position: absolute;
				content: '';
				top: 2px;
				left: 12px;
				width: 1px;
				height: 20px;
				background: currentColor;
			}
		}
	}
	button[aria-expanded='true'] {
		color: #fff;
		// color: ${props => props.theme.blue};
		.icon {
			&::after {
				width: 0;
			}
		}
		+ .accordion-content {
			opacity: 1;
			max-height: 12em;
			transition: all 200ms linear;
			will-change: opacity, max-height;
		}
	}

`;


export const AccordionContent = styled.div`
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: opacity 200ms linear, max-height 200ms linear;
	will-change: opacity, max-height;
	p {
		font-size: 14px;
		// font-weight: 300;
		margin: .5em 0;
		@media only screen and ${breakpoint.device.s} {
			margin: 0;
			padding: 0.5em 0;
		}
		@media only screen and ${breakpoint.device.xs} {
			margin: 0;
			padding: 0.3em 0;
		}
	}
	a {
		&:hover {
			color: #fff;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	ul {
		list-style: none;
		margin:0;
		padding: .2em;
		
		display: inline-block;
	}
	li {
		float: left;	
		// width: 30px;
		// height: 30px;
		margin: .3em;
		padding: 0;
	}
`;