import {ContactUsSection,
	//  ContactUsBg
} from '../../styles/ContactUs/ContactUsStyles'
 import { useState, useEffect } from 'react';

 import ContactUsFullBg from '../../assets/images/ContactUsFullBg.svg';
 import mobileImage from '../../assets/images/ContactUsMobile.svg';
import NavbarBlack from '../NavbarBlack';
import TextContactUs from '../textComponents/ContactUs/TextContactUs';
import TextContactUs2 from '../textComponents/ContactUs/TextContactUs2';

const ContactUs = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
		const imageUrl = windowWidth >= 430 ? ContactUsFullBg : mobileImage;

		useEffect(() => {
			const handleWindowResize = () => {
				setWindowWidth(window.innerWidth);
			};

			window.addEventListener('resize', handleWindowResize);

			return () => {
				window.removeEventListener('resize', handleWindowResize);
			};
		}, []);
  return (
		<ContactUsSection style={{
					backgroundImage: `url(${imageUrl})`,
				}}>
			{/* <ContactUsBg
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
			> */}
				<NavbarBlack />
				<TextContactUs />
				<TextContactUs2 />
			{/* </ContactUsBg> */}
		</ContactUsSection>
	);
}

export default ContactUs