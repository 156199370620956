/* eslint-disable no-unused-vars */
import { useState } from 'react';

import {
	Title,
	TextContainer,
	SocialLinks,
} from '../../../styles/Authors/TextAuthors4Styles';
import { useTranslation } from 'react-i18next';
const TextAuthors4 = () => {
	const [t] = useTranslation();
	const [showLinks, setShowLinks] = useState(false);

	const handleJoinClick = () => {
		setShowLinks(!showLinks);
	};

	return (
		<TextContainer>
			<Title>
				{t('authors.text11')} <br></br>
				<span>
					<a onClick={handleJoinClick}>{t('authors.text12')} </a>
				</span>
			</Title>
			{showLinks && (
				<SocialLinks>
					<a
						src='linkedInLiberada'
						href='https://es.linkedin.com/company/liberada?trk=public_profile_topcard-current-company'
						target='_blank'
						rel='noreferrer'
					>
						LinkedIn
					</a>
					<a
						src='instagramLiberada'
						href='https://instagram.com/liberadanft?igshid=YmMyMTA2M2Y='
						target='_blank'
						rel='noreferrer'
					>
						Instagram
					</a>
					<a
						src='twitterLiberada'
						href='https://twitter.com/LiberadaNFT'
						target='_blank'
						rel='noreferrer'
					>
						Twitter
					</a>
					<a
						src='facebookLiberada'
						href='https://www.facebook.com/people/NFT-Books-Liberada/100090957735416/'
						target='_blank'
						rel='noreferrer'
					>
						Facebook
					</a>
				</SocialLinks>
			)}
		</TextContainer>
	);
};

export default TextAuthors4;
