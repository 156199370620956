import FAQsFullBg from '../../assets/images/FAQsFullBg.svg';
import NavbarWhite from '../NavbarWhite';
import { useState } from 'react';
// import { Link } from 'react-router-dom';
import Footer from '../Footer';
import {
	FaqSection,
	FaqContainer,
	Container,
	Title,
	AccordionWrapper,
	AccordionItem,
	// AccordionButton,
	AccordionContent,
} from '../../styles/FAQs/FAQStyles';

import { useTranslation } from 'react-i18next';

const FAQs = () => {
	// eslint-disable-next-line no-unused-vars
	const [t] = useTranslation();
// Retrieve the array of objects using returnObjects option
	const accordionItems = t('accordiondata.items', { returnObjects: true });
	console.log(accordionItems, typeof(accordionItems));
  
	const [activeItem, setActiveItem] = useState(null);

	const toggleAccordion = index => {
		setActiveItem(activeItem === index ? null : index);
	};

	

	return (
		<FaqSection>
			<FaqContainer
				style={{
					backgroundImage: `url(${FAQsFullBg})`,
				}}
			>
				{' '}
				<NavbarWhite />
				<Container>
					<Title>
						{t('faq.header1')} <span>{t('faq.header2')}</span>
						{/* Frequently <span>Asked</span> Questions */}
					</Title>

					<AccordionWrapper>
						{/* Map through your accordion items */}
						{accordionItems.map((item, index) => (
							<AccordionItem className='accordion-item' key={index}>
								<button
									aria-expanded={activeItem === index ? 'true' : 'false'}
									onClick={() => toggleAccordion(index)}
								>
									{/* <h2 className='accordion-title'>{item.title}</h2> */}
									<h2 className='accordion-title'>{t(item.question)}</h2>
									<span className='icon' aria-hidden='true'></span>
								</button>
								<AccordionContent
									style={{
										opacity: activeItem === index ? 1 : 0,
										maxHeight: activeItem === index ? '12em' : 0,
									}}
								>
									<div>
										<p>{t(item.content1)}</p>
									</div>

									<div>
										<p>{t(item.content2)}</p>

										{item.wallets && (
											<div>
												<ul>
													{/* Map through the wallets array and display wallet links */}
													{item.wallets.map((wallet, walletIndex) => (
														<li key={walletIndex}>
															{Object.keys(wallet).map((key, valueIndex) => (
																<a
																	href={wallet[key]}
																	key={valueIndex}
																	rel='noreferrer'
																	target='_blank'
																>
																	{t(key)}
																</a>
															))}
														</li>
													))}
												</ul>
											</div>
										)}

										<p>{t(item.content3)}</p>
									</div>

									<div>
										<p>{t(item.content4)}</p>
										<a
											href='https://cardano.org/'
											rel='noreferrer'
											target='_blank'
										>
											{t(item.cardanoweb)}
										</a>
									</div>
									<div>
										<p>{t(item.content5)}</p>
									</div>
									{/* <p>
											{t(item.content1)}<a href='mailto: contact@liberada.io'>{t(item.liberadamail)}{' '}
											</a>{' '}
											{t(item.content13)}{' '}
										</p> */}
									{/* <p>{t(item.content3)}</p>
									<p>{t(item.content4)}</p>
									<p>{t(item.content5)}</p>
									<p>
										{t(item.content6)}
										<a href='/marketplace'> {t(item.content7)} </a>
										{t(item.content8)}
									</p>
									<p>{t(item.content9)}</p>
									<p>{t(item.content10)}</p> */}
								</AccordionContent>
							</AccordionItem>
						))}
					</AccordionWrapper>
				</Container>
			</FaqContainer>
			<Footer />
		</FaqSection>
	);
};

export default FAQs;





	 