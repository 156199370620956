import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
	}
`;

export const Title = styled.h2`
	// font-family: 'Tomorrow';
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	text-align: center;
	width: 40%;
	margin: 0 0 20em 0;

	span {
		color: #fff;
		cursor: pointer;
		a {
			text-decoration: none;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 30px;
		width: 70%;
		margin: 5em 2em 15em 2em;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		width: 70%;
		margin: 5em 2em 10em 2em;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin: 3em 0em 9em 0em;
		font-size: 20px;
		width: 70%;
	}
`;

export const SocialLinks = styled.div`
	position: absolute;
	// top: 15%;
	bottom: 75%;
	display: flex;
	gap: 2em;

	a {
		color: #fff;
		text-decoration: none;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 300;
		line-height: 24px;
		&:hover {
			text-decoration: underline;
		}
	}

	@media only screen and ${breakpoint.device.m} {
		gap: 1em;
		bottom: 52%;
	}

	@media only screen and ${breakpoint.device.s} {
		gap: 1em;
		bottom: 45%;
	}

	@media only screen and ${breakpoint.device.xs} {
		gap: 1em;
		bottom: 32%;
	}
`;