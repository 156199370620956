import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	width: 32%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 30em 53em 5em auto;

	@media only screen and ${breakpoint.device.xlg} {
		margin: 25em 18em 5em 8em;
		width: 25%;
	}

	@media only screen and ${breakpoint.device.lg} {
		margin: 20em 18em 5em 8em;
		// margin: 17em 18em 5em 8em;fixing
		width: 25%;
	}

	@media only screen and ${breakpoint.device.l} {
		margin: 6em 18em 5em 8em;
		width: 35%;
	}

	@media only screen and ${breakpoint.device.m} {
		margin: 6em 18em 5em 8em;
		width: 33%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 15em 0 2em 2em;
		width: 63%;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin: 7em 0 2em 2em;
		width: 63%;
	}
`;


export const Title = styled.h2`
	// font-family: 'Tomorrow';
	font-style: normal;
	weight: 400;
	font-size: 50px;
	padding-bottom: 0.5em;

	span {
		color: #fff;
	}
	
	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

export const SubTitle = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 18px;

	@media only screen and ${breakpoint.device.m} {
		font-size: 16px;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
