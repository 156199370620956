import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';



export const TextContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media only screen and ${breakpoint.device.xlg} {
		width: 100%;
		margin: auto;
	}
	@media only screen and ${breakpoint.device.lg} {
		width: 100%;
		margin: auto;
	}
	@media only screen and ${breakpoint.device.m} {
		width: 100%;
		margin: auto;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
	}
`;
// export const SVG = styled.div`
  

// 	width: 100%;
	

// 	// @media only screen and ${breakpoint.device.s} {
// 	// 	background-size: auto;
// 	// 	background-position: center;
// 	// 	background-repeat: no-repeat;
// 	// }
// `;


export const Title = styled.h2`
	// font-family: 'Tomorrow';
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	text-align: center;
	width: 41%;
	margin: 15em 2em 25em 2em;

	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.xlg} {
		margin: 20em 0em 15em 0em;
		font-size: 30px;
		width: 43%;
	}

	@media only screen and ${breakpoint.device.lg} {
		margin: 8em 0em 15em 0em;
		// margin: 2em 0em 9em 0em;fixing
		font-size: 30px;
		width: 43%;
	}

	@media only screen and ${breakpoint.device.l} {
		margin: 10em 0em 14em 0em;
		font-size: 30px;
		width: 58%;
	}
	@media only screen and ${breakpoint.device.m} {
		margin: 2em 0em 12em 0em;
		font-size: 30px;
		width: 63%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 2em 0em 10em 0em;
		font-size: 20px;
		// width: 76%;
		width: 80%;
	}
	@media only screen and ${breakpoint.device.xs} {
		// margin: 5em 0em 10em 0em;
		font-size: 16px;
		width: 70%;

		padding-bottom: 0em;
		margin: 0em 0em 11em 0em;
	}
`;


export const LinkFile = styled.a`
    cursor: pointer;
	text-decoration: none;

	&:hover{
	
		text-decoration: underline;
	}
`;
