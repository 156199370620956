import styled from 'styled-components';

export const ContactUsSection = styled.section`
	// width: 100vw;
	// height: 100vh;
	min-height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

// export const ContactUsBg = styled.div`
// 	min-height: 100vh;
// 	background-size: cover;
// 	background-position: center;
// 	background-repeat: no-repeat;
// `;


