/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	// Textauthors,
	TextContainer,
	MarketplaceLink,
} from '../../../styles/Authors/TextAuthors3Styles';
import { useTranslation } from 'react-i18next';
const TextAuthors3 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				{t('authors.text7')} <br></br>
				{t('authors.text8')}
			</Title>
			<SubTitle>{t('authors.text9')}</SubTitle>
			<MarketplaceLink to='/marketplace'>{t('authors.text10')}</MarketplaceLink>
			{/* <Title>
				Be your <br></br>own publisher
			</Title>
			<SubTitle>
				Join our marketplace and take advantage of the Cardano network to upload your work. Sell it as an NFT and define specific conditions. This
				empowers you to earn &quot;royalties&quot; forever from future sales of	your work.
			</SubTitle>
			<MarketplaceLink to='/marketplace'>MARKETPLACE</MarketplaceLink> */}
		</TextContainer>
	);
};

export default TextAuthors3;
