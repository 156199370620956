const size = {
	xs: '375px',
	s: '430px',
	m: '820px',
	l: '1024px',
	lg: '1440px',
	xlg: '2000px',
};
const device = {
	xs: `(max-width: ${size.xs})`,
	s: `(max-width: ${size.s})`,
	m: `(max-width: ${size.m})`,
	l: `(max-width: ${size.l})`,
	lg: `(max-width: ${size.lg})`,
	xlg: `(max-width: ${size.xlg})`,
};
export default { size, device };
