/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
	Container,
	BookDetails,
	Author,
	TotalSupply,
	Title,
	Price,
	Text,
	Close,
	Ribbon,
	RibbonSoldOut,
	// PriceContainer,
	BuyBtn,
	ImageBook,
	ModalView,
	JpegBtn,
} from '../styles/DetailCardStyles';

// import bookImg from '../assets/images/Alice_in_Wonderland.jpg'
import { AnimatePresence } from 'framer-motion';
// import { Books } from '../Data';
import { useTranslation } from 'react-i18next';

const DetailCard = ({ onClose, item }) => {

const [t, i18n] = useTranslation('global');
	 const isSoldOut = parseFloat(item.sold) >= parseFloat(item.totalSupply);
		const uniqueRelease =item.typeBadge;

		

	

	return (
		<AnimatePresence>
			{item ? (
				<ModalView
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
						transition: { duration: 0.7 },
					}}
					exit={{
						opacity: 0,
						transition: { duration: 0.5 },
					}}
				>
					<Container key={item.id}>
						<BookDetails>
							<Title>{item.title}</Title>
							<Author>{item.author}</Author>

							<Text>{item.description}</Text>
							<TotalSupply>
								{item.totalSupplyTitle} {item.sold} / {item.totalSupply}
							</TotalSupply>
							</BookDetails>	
					{/* <PriceContainer> */}
						{isSoldOut && !uniqueRelease ? (
								<Price>{item.jpegstore}</Price>
							) : (
								<Price>{item.price}</Price>
							)}

							{isSoldOut && !uniqueRelease ? (
								<JpegBtn
									href={`${item.jpegstorelink}`}
									target='_blank'
									rel='noreferrer'
								>
									JPEG.STORE
								</JpegBtn>
							) : (
								<BuyBtn
									href={`${item.buynow}`}
									target='_blank'
									rel='noreferrer'
								>
									{t('carddetail.text1')}
								</BuyBtn>
							)}
							{/* </PriceContainer> */}
					
						
<Close onClick={onClose}>{t('carddetail.text3')}</Close>
						
							
							

						<ImageBook src={`../Books/${item.image}`} alt={`${item.author}`} />

						<Ribbon>
							{isSoldOut && !uniqueRelease ? (
								<RibbonSoldOut> {t('card.text5')} </RibbonSoldOut>
							) : (
								''
							)}
						</Ribbon>
					</Container>
				</ModalView>
			) : (
				''
			)}
		</AnimatePresence>
	);
};

export default DetailCard;


