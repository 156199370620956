import styled from 'styled-components';

import breakpoint from '../Variables/MediaQ';

export const Container = styled.div`
	position: relative;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.315);
	cursor: pointer;
	transition: all 0.8s cubic-bezier(0.15, 0.83, 0.66, 1);

	&:hover {
		transform: scale(1.05);
	}
`;
export const Card = styled.div`
	img {
		width: 100%;
		height: 412px;
		object-position: top;
		object-fit: contain;
	}

	@media only screen and ${breakpoint.device.s} {
		height: 285px;
	}
`;

export const Ribbon = styled.div`
	width: 150px;
	height: 150px;
	overflow: hidden;
	position: absolute;
	top: -2px;
	right: -2px;
`;

export const RibbonGold = styled.div`
	left: -20px;
	top: 40px;
	transform: rotate(45deg);
	position: absolute;
	display: block;
	width: 230px;
	padding: 5px 0;

	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;

	font-family: Tomorrow;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;

	background: radial-gradient(
			ellipse farthest-corner at right bottom,
			#fedb37 0%,
			#fdb931 8%,
			#9f7928 30%,
			#8a6e2f 40%,
			transparent 80%
		),
		radial-gradient(
			ellipse farthest-corner at left top,
			#ffffff 0%,
			#ffffac 8%,
			#d1b464 25%,
			#5d4a1f 62.5%,
			#5d4a1f 100%
		);
`;
export const RibbonSoldOut = styled.div`
	left: -20px;
	top: 40px;
	transform: rotate(45deg);
	position: absolute;
	display: block;
	width: 230px;
	padding: 5px 0;

	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
	color: #000;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	text-align: center;

	font-family: Tomorrow;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;

	background: rgb(134 227 254 / 67%);
	backdrop-filter: blur(2px);
`;

export const ContainerBullets = styled.div`
	position: absolute;
	right: 0;
	left: 0;
	bottom: 35%;
	display: flex;
	align-items: flex-end;
	padding: 20px;
	color: #fff;

	span {
		margin: auto;
	}

	i {
		margin-right: 10px;
	}
	@media only screen and ${breakpoint.device.s} {
		bottom: 45%;
	}
`;

export const ContainerInfo = styled.div`
	height: 147px;
	background-color: #fff;
	display: flex;
	flex-direction: column;

	padding: 8px;
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;

	@media only screen and ${breakpoint.device.s} {
		height: 120px;
	}
`;

export const ShortTitle = styled.h2`
	color: #000;
	font-size: 15px;
	font-weight: 300;
	margin-bottom: 3px;
`;
export const Author = styled.p`
	color: #000;
	// font-family: 'Roboto';
	font-size: 15px;
	font-weight: 300;
	margin-bottom: 3px;

	@media only screen and ${breakpoint.device.s} {
		font-size: 12px;
	}
`;


export const Rarity = styled.p`
	color: #000;
	// font-family: 'Roboto';
	font-size: 15px;
	font-weight: 300;
	color: #a1b2bc;
	margin-bottom: 3px;


	@media only screen and ${breakpoint.device.s} {
		font-size: 12px;
	}
`;
export const TotalSupply = styled.p`
	font-size: 15px;
	font-weight: 300;
	color: #a1b2bc;

	// @media only screen and ${breakpoint.device.s} {
	// 	font-size: 10px;
	// }
`;

export const Price = styled.h1`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 10px;

	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		font-size: 15px;
		bottom: 5px;
	}

	@media only screen and ${breakpoint.device.xs} {
		font-size: 12px;
		bottom: 5px;
	}
`;

export const BuyBtn = styled.a`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 10px;
	right: 10px;

	text-transform: uppercase;
	color: #64dcff;
	font-size: 15px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		// font-size: 12px;
		bottom: 5px;
	}

	@media only screen and ${breakpoint.device.xs} {
		font-size: 12px;
		bottom: 5px;
	}
`;

export const JpegBtn = styled.a`
	justify-content: center;
	margin-bottom: 0.5em;
	align-self: flex-start;
	position: absolute;
	bottom: 10px;
	right: 10px;

	text-transform: uppercase;
	color: #64dcff;
	font-size: 15px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	@media only screen and ${breakpoint.device.s} {
		// font-size: 12px;
		bottom: 5px;
	}

	@media only screen and ${breakpoint.device.xs} {
		font-size: 12px;
		bottom: 5px;
	}
`;
