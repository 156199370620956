
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import breakpoint from '../styles/Variables/MediaQ';


export const FooterSection = styled.footer`
	position: relative;
	// width: 100%;
	// height: 383px;
	height: auto;
	left: 0px;
	bottom: 0px;

	padding: 2.5em 1.25em;
	background: #000;
	color: #ccc;

	overflow: hidden;

	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		// width: 80%;
		padding: 1rem;
		// height: 460px;
	}
`;

export const FooterContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	justify-content: center;
	gap: 2rem;

	@media only screen and ${breakpoint.device.s} {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}
`;

export const AboutSection = styled.div`
	@media only screen and ${breakpoint.device.s} {
		margin-top: 2em;
		text-align: left;
	}
`;
export const Text = styled.h2`
	font-weight: 400;
	font-size: 30px;

	@media only screen and ${breakpoint.device.s} {
		font-weight: 400;
		font-size: 20px;
	}
`;
	
	

export const MainSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	// justify-content: center;

	@media only screen and ${breakpoint.device.s} {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
`;
export const Items = styled.ul`
	list-style-type: none;

`;
export const List = styled.li`
margin:10px;
`
export const Links = styled.a`
	// font-family: 'Roboto';
	font-size: 16px;
	font-weight: 400;

	&:hover {
		color: #64dcff;
	}
`;
export const PrivacyLink = styled(Link)`
	font-size: 16px;
	font-weight: 400;
	&:hover {
		color: #64dcff;
	}
`;



export const Copyright = styled.div`
	margin-top: 12rem;
	// text-align: center;
	padding: 1rem 0 0 0;
	// border-top: 1px solid rgba(255, 255, 255, 0.2);

	font-family: 'Roboto';
	font-size: 16px;
	font-weight: 400;

	span {
		color: grey;
	}

	@media only screen and ${breakpoint.device.s} {
		padding: 0;
		margin: 2em auto;
	}
`;