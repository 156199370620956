import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';

export const Container = styled.div`
	min-height: 100vh;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	
`;
export const ContainerCountDown = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.h2`
	text-align: center;
	font-size: 40px;
	font-weight: 400;
	margin-bottom: 2em;
	

	@media only screen and ${breakpoint.device.s} {
		margin-bottom: 1em;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin-bottom: 1em;
	}
`;


export const CountDown = styled.div`
	width: 100px;
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 2em;
	margin-right: 2em;

	@media only screen and ${breakpoint.device.s} {
		width: 40px;
		height: 30px;
		margin-left: 1.5em;
		margin-right: 1.5em;
	}

	@media only screen and ${breakpoint.device.xs} {
		width: 40px;
		height: 30px;
		margin-left: 1.5em;
		margin-right: 1.5em;
	}
`;
export const Text = styled.h1`
    color: #64DCFF;
	text-align: center;
	font-family: Tomorrow;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
`;
export const Count = styled.h2`
	text-align: center;
	font-family: Tomorrow;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

`;