/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	TextContainer,
	LinkMarketplace,
} from '../../../styles/Readers/TextReaders4Styles';
import { useTranslation } from 'react-i18next';

const TextReaders4 = () => {
	const [t, i18n] = useTranslation('global');
	return (
		<TextContainer>
			<Title>{t('readers.text11')}</Title>
			<SubTitle>
				{t('readers.text12')}{' '}
				<span>
					<LinkMarketplace to='/marketplace'>
						{' '}
						{t('readers.text13')}{' '}
					</LinkMarketplace>
				</span>{' '}
				{t('readers.text14')}
			</SubTitle>

			{/* <Title>How does it works?</Title>
			<SubTitle>
				It&apos;s easy, you just have to{' '}
				<span>
					<LinkMarketplace to='/marketplace'>
						{' '}
						access our platform{' '}
					</LinkMarketplace>
				</span>{' '}
				within the Cardano network, search for your favorite author, and buy
				their work. If the value of the NFT has increased, you can sell it on
				the market and profit from your purchase.
			</SubTitle>

			 */}
		</TextContainer>
	);
};

export default TextReaders4;
