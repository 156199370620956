import PrivacyFullBg from '../../assets/images/PrivacyPolicyFullBg.svg';
import mobileImage from '../../assets/images/PrivacyMobile.svg';
import NavbarBlack from '../NavbarBlack';
import Footer from '../Footer'
 import { useState, useEffect } from 'react';
import { 
	Background, 
	Section } from "../../styles/PrivacyPolicy/PrivacyPolicyStyles";
import TextPrivacyPolicy from '../textComponents/PrivacyPolicy/TextPrivacyPolicy';

const PrivacyPolicy = () => {

        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
				const imageUrl = windowWidth >= 430 ? PrivacyFullBg : mobileImage;

				useEffect(() => {
					const handleWindowResize = () => {
						setWindowWidth(window.innerWidth);
					};

					window.addEventListener('resize', handleWindowResize);

					return () => {
						window.removeEventListener('resize', handleWindowResize);
					};
				}, []);
	  return (
			<>
				<Section>
					<Background
						style={{
							backgroundImage: `url(${imageUrl})`,
						}}
					>
						<NavbarBlack />
						<TextPrivacyPolicy />
					</Background>
					<Footer />
				</Section>
			</>
		);
};

export default PrivacyPolicy;
