/* eslint-disable no-unused-vars */
import axios from 'axios';
const cheerio = require('cheerio');

// Función para obtener los datos de la página web
export async function fetchWebData(policy) {
	try {
		const response = await axios.get(
			`https://cardanoscan.io/tokenPolicy/${policy}`
		);
		const $ = cheerio.load(response.data);
		const extractedData = $('.gap-x-10').find('.textColor1').text();
		// console.log(`Fetched data for policy ${policy}:`, extractedData); OK

		return extractedData;
	} catch (error) {
		console.error('Error fetching data:', error);
		return null;
	}
}

export async function updateSoldData() {
	const updatedBooks = await Promise.all(
		Books.map(async book => {
			const extractedData = await fetchWebData(book.policy);
			if (extractedData) {
				// console.log(`Updating sold for ${book.policy} to ${extractedData}`);
				return { ...book, sold: extractedData };
			}
			return book;
		})
	);

	// Update the Books array with the updated data
	Books.length = 0;
	Books.push(...updatedBooks);
}

export const Books = [
	{
		title: `Alice's Adventures in Wonderland`,
		shortTitle: `Alice's Adventures in Wonderland`,
		author: 'Lewis Carroll',
		sold: '1',
		totalSupply: '1',
		totalSupplyTitle: 'Supply:',
		typeBadge: 'Unique NFT',
		singleRelease: 'Single Release',
		price: 'UNIQUE',
		image:
			'/LewisCarroll/AliceAdventuresInWonderlandByLewis Carroll_Liberada_V1.png',
		id: '1',
		description: `The acclaimed literary classic "Alice's Adventures in Wonderland" immerses us in a surreal and whimsical world. The narrative captivates readers by delving into the internal conflicts of the protagonist, Alice. The author's elegant and poetic prose creates witty and humorous dialogues among the characters. Furthermore, the story invites us to contemplate themes of identity and reality, exploring the nature of perception and illusion within a magical and enigmatic setting. ~English Version~`,
		// buynow: 'https://pay.nmkr.io/?p=f75a84a9579a4d99bdf03d42d45a34bd&c=1',
		// policy: '7d73dfc84049b2086e13f4f661de89605eebf100404de6af31904f87',
	},

	{
		title: 'A Room with a View',
		shortTitle: 'A Room with a View',
		author: 'E.M. Forster',
		sold: '1',
		totalSupply: '1',
		totalSupplyTitle: 'Supply:',
		typeBadge: 'Unique NFT',
		singleRelease: 'Release',
		price: 'UNIQUE',
		image: 'E.M.Forster/ARoomWithaViewByE.M.Forster_V1_Liberada.png',
		id: '2',
		description: `A Room With a View by E. M. Forster explores the encounters and miss encounters of its characters within a restrictive social environment. Through the protagonist's journey in Italy, the novel delves into themes of personal liberation and the struggle against social conventions. The narrative captivates readers with its vivid descriptions of the settings and the subtle evolution of the characters. It combines elements of romance, personal exploration, and social criticism, providing a captivating story that challenges societal norms and expectations. ~English Version~`,
		// buynow:
		// 	'https://pay.nmkr.io/?p=86ab0ead530444a4a267f4d5542e6179&n=983be8685ea84b92bea992fd073da9ed&tc=1000',
	},

	{
		title: 'The Poetics of Aristotle',
		shortTitle: 'The Poetics of Aristotle',
		author: 'Aristotle',
		sold: '1',
		totalSupply: '1',
		totalSupplyTitle: 'Supply:',
		typeBadge: 'Unique NFT',
		singleRelease: 'Single Release',
		price: 'UNIQUE',
		image: 'Aristotle/ThePoeticsOfAristotleByAristotle_V1_Liberada.png',
		id: '3',
		description: `"The Poetics of Aristotle" offers writers and poetry enthusiasts a valuable set of principles and techniques applicable to literary creation. Through its analysis of structure, characters, and emotions, Aristotle provides practical tools for developing effective poetic works. His concepts of imitation, catharsis, and unity of action continue to influence literary theory and practice today. This classic work becomes an essential guide for those seeking to understand and apply the foundations of poetry in their writing. ~English Version~`,
		// buynow:
		// 	'https://pay.nmkr.io/?p=86ab0ead530444a4a267f4d5542e6179&n=983be8685ea84b92bea992fd073da9ed&tc=1000',
	},
	{
		title:
			'Discourse on the Method of Rightly Conducting Ones Reason of Seeking Truth in the Sciences',
		shortTitle: 'Discourse on the Method of Rightly...',
		author: 'Rene Descartes',
		sold: '1',
		totalSupply: '1',
		totalSupplyTitle: 'Supply:',
		typeBadge: 'Unique NFT',
		singleRelease: 'Single Release',
		price: 'UNIQUE',
		image:
			'/ReneDescartes/DiscourseontheMethodofRightlyConductingOnesReasonofSeekingTruthintheSciencesbyRene Descartes_V1_Liberada.png',
		id: '4',
		description: `"Discourse on the Method of Rightly Conducting One's Reason and of Seeking Truth in the Sciences" by Descartes is a philosophical essay that explores the method for properly conducting reason and seeking truth in the sciences. Through his methodical doubt and emphasis on reason as a guide, the author establishes a framework for rational thinking and the pursuit of knowledge. Descartes' analytical approach and his emphasis on critical doubt continue to be influential in philosophy and scientific thought. ~English Version~`,
		// buynow:
		// 	'https://pay.nmkr.io/?p=86ab0ead530444a4a267f4d5542e6179&n=983be8685ea84b92bea992fd073da9ed&tc=1000',
	},

	{
		title: 'Fundamental Principles of the Metaphysic of Morals',
		shortTitle: 'Fundamental Principles...',
		author: 'Immanuel Kant',
		sold: '1',
		totalSupply: '1',
		totalSupplyTitle: 'Supply:',
		typeBadge: 'Unique NFT',
		singleRelease: 'Single Release',
		price: 'UNIQUE',
		image:
			'/ImmanuelKant/FundamentalPrinciplesoftheMetaphysicofMoralsByImmanuelKant_V1_Liberada.png',
		id: '5',
		description: `"Fundamental Principles of the Metaphysic of Morals" by Immanuel Kant is a philosophical treatise seeking to establish the fundamental principles of morality. Through his focus on the categorical imperative and the good will, the author proposes an ethics based on duty and universality. The work offers a logical and systematic framework for moral decision-making, and its influence in ethics and moral philosophy is widely recognized. ~English Version~`,
		// buynow:
		// 	'https://pay.nmkr.io/?p=86ab0ead530444a4a267f4d5542e6179&n=983be8685ea84b92bea992fd073da9ed&tc=1000',
	},

	{
		title: `Alice's Adventures in Wonderland`,
		shortTitle: `Alice's Adventures in Wonderland`,
		author: 'Lewis Carroll',
		sold: '',
		totalSupply: '50',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image:
			'/LewisCarroll/AliceAdventuresInWonderlandByLewis Carroll_Liberada_V2.png',
		id: '6',
		description: `The acclaimed literary classic "Alice's Adventures in Wonderland" immerses us in a surreal and whimsical world. The narrative captivates readers by delving into the internal conflicts of the protagonist, Alice. The author's elegant and poetic prose creates witty and humorous dialogues among the characters. Furthermore, the story invites us to contemplate themes of identity and reality, exploring the nature of perception and illusion within a magical and enigmatic setting. ~English Version~`,
		buynow: 'https://pay.nmkr.io/?p=5c6fe1027eac4d81a54e95df4aa0d69b&c=1',
		policy: 'b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8',
		jpegstore: 'BUY IT >',
		jpegstorelink:
			'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: `Metamorphosis`,
		shortTitle: `Metamorphosis`,
		author: 'Franz Kafka',
		sold: '',
		totalSupply: '100',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image: '/Kafka/MethamorphosisByKafka_V1_Liberada.png',
		id: '7',
		description: `Metamorphosis is arguably Franz Kafka's most recognized literary work. Published in Germany in 1915 as Die Verwandlung, this dark short novel or novella, has made its way as one of the most important works of literary fiction of the 20th Century. Gregor Samsa, a traveling salesman living with his family in Prague, wakes up one day to find himself transformed, without rhyme or reason, into an indescribable creature. Understandably, Gregor Samsa's life changes abruptly and dramatically and the novel focuses in how this change affects the mind of the protagonist and the ones around him. ~English Version~`,
		buynow: 'https://pay.nmkr.io/?p=2a062fbb023c4595b3d6c59465d671aa&c=1',
		policy: '2e78618a4e99bf1a6e4eb2552300dd7524acb0069c6365e0b2b177e1',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},

	{
		title: 'The Poetics of Aristotle',
		shortTitle: 'The Poetics of Aristotle',
		author: 'Aristotle',
		sold: '',
		totalSupply: '50',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image: 'Aristotle/ThePoeticsOfAristotleByAristotle_V2_Liberada.png',
		id: '8',
		description: `"The Poetics of Aristotle" offers writers and poetry enthusiasts a valuable set of principles and techniques applicable to literary creation. Through its analysis of structure, characters, and emotions, Aristotle provides practical tools for developing effective poetic works. His concepts of imitation, catharsis, and unity of action continue to influence literary theory and practice today. This classic work becomes an essential guide for those seeking to understand and apply the foundations of poetry in their writing. ~English Version~`,
		buynow: 'https://pay.nmkr.io/?p=4b5c7d09c0ff485aa14c505e5a202cdb&c=1',
		policy: '65182f1140484e19acedbd98bb0508ec8bffe87d8357690b9e1a58b9',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: 'Poemas Que Abrazan El Alma',
		shortTitle: 'Poemas Que Abrazan El Alma',
		author: 'Julia Orozco',
		sold: '',
		totalSupply: '100',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image: 'JuliaOrozco/PoemasQueAbrazanElAlmaByJuliaOrozco_V1_Liberada.png',
		id: '9',
		description: `Escribo al ser humano, para desde mi misma, mis tristezas y alegrías les ayuden como a mí, a soportar las tormentas de la vida.`,
		buynow: 'https://pay.nmkr.io/?p=b16cdf728f7540a9881cab759e56a5a3&c=1',
		policy: 'b8487b0f6b3cd6cc576042bdaeb6b224284d0973322b4e6a0d0211cf',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: 'Leviathan',
		shortTitle: 'Leviathan',
		author: 'Thomas Hobbes',
		sold: '',
		totalSupply: '100',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image: 'ThomasHobbes/LeviathanByThomasHobbes_V1_Liberada.png',
		id: '10',
		description: `Hobbes presents us with a significant philosophical treatise titled 'Leviathan.' In this work, the author delves into human nature and the social contract, advocating for the necessity of an absolute sovereign and depicting the state as a Leviathan. Through this approach, Hobbes establishes a theoretical framework for political organization and peaceful coexistence. His work challenges traditional notions of power and authority, and its influence on political philosophy and social thought is widely recognized.`,
		buynow: 'https://pay.nmkr.io/?p=4617a0d69fa84f29a2f8131b156cc0f0&c=1',
		policy: '66278629eedda412ff3fd3a249ccef1c457b25afee8cd12479f743f5',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: 'A Room With a View',
		shortTitle: 'A Room With a View',
		author: 'E.M. Forster',
		sold: '',
		totalSupply: '100',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image: '/E.M.Forster/ARoomWithaViewByEMForster_V2_Liberada.png',
		id: '11',
		description: `A Room With a View by E.M. Forster explores the encounters and misencounters of its characters within a restrictive social environment. Through the protagonist's journey in Italy, the novel delves into themes of personal liberation and the struggle against social conventions. The narrative captivates readers with its vivid descriptions of the settings and the subtle evolution of the characters. It combines elements of romance, personal exploration, and social criticism, providing a captivating story that challenges societal norms and expectations.`,
		buynow: 'https://pay.nmkr.io/?p=c8a2a30153104e8897e4a3a6364f6b32&c=1',
		policy: '0fed52d90c2cbf1a085541d2cef04c11b18320a3a8f4c565c1781535',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: 'The Adventures of Sherlock Holmes',
		shortTitle: 'The Adventures of Sherlock Holmes',
		author: 'Arthur Conan Doyle',
		sold: '',
		totalSupply: '100',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '17,00 ADA',
		image:
			'/ArthurConanDoyle/TheAdventuresofSherlockHolmesbyArthurConanDoyle_V1_Liberada.png',
		id: '12',
		description: `The Adventures of Sherlock Holmes is a collection of short stories by British writer Arthur Conan Doyle, first published on 14 October 1892. It contains the earliest short stories featuring the consulting detective Sherlock Holmes, which had been published in twelve monthly issues of The Strand Magazine from July 1891 to June 1892. The stories are collected in the same sequence, which is not supported by any fictional chronology. The only characters common to all twelve are Holmes and Dr. Watson, and all are related in first-person narrative from Watson's point of view.`,
		buynow: 'https://pay.nmkr.io/?p=5a04d95346724a438ac7f1ac3fab1a92&c=1',
		policy: '77c4522fa15b7f84537af1aac41449b3aaa18f824c60fbc81e63df10',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
	{
		title: 'The Samurai Legacy',
		shortTitle: 'Chapter one: Mikee',
		author: 'Adrià Cid',
		sold: '',
		totalSupply: '500',
		totalSupplyTitle: 'Supply:',
		typeBadge: '',
		singleRelease: 'First Release',
		price: '25,00 ADA',
		image: '/CollabBooks/TheSamuraiLegacy/TheSamuraiLegacy_V1_Liberada.png',
		id: '13',
		description: `In the village of the Monkey Clan, Mikee must face the final trial for the emperor to appoint him a samurai. In this first chapter of The Samurai Legacy, one of the main characters of the saga is introduced, along with the mystery that surrounds him. It is the first native work on the Cardano network based on an NFT project.`,
		buynow: 'https://pay.nmkr.io/?p=417987c00d2d42f2bfcf7a6e4a8dc4fc&c=1',
		policy: '0d7005ea07aafd2b58a3c0794194d0518702a5f1cf5c8a32db9d4e3e',
		// jpegstore: 'BUY IT >',
		// jpegstorelink:
		// 	'https://www.jpg.store/collection/b698b0e15977403dbd340a4ffb3d8ddf5607c5b935b6a134b1499cf8?tab=items',
	},
];
