/* eslint-disable no-unused-vars */
import styled,{css} from 'styled-components';
// import { motion } from 'framer-motion';
// export const NavContainer = styled.div`

export const NavContainer = styled.div`
	width: 100%;
	position: sticky;
	top: 0;
	z-index: 99;
	
	
	@media screen and (max-width: 960px) {
		overflow: hidden;
	}
`;

export const NavWrapper = styled.div`
	//  margin: 1.25em auto;
	padding-top: 1.25em;
	padding-bottom: 0.5em;
	width: 100%;

	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	
`;

export const LogoDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-left: 1.25em;

	.logo {
		width: 142px;
		height: 28px;
		// width: 260px;
		// height: 51px;
		@media screen and (max-width: 960px) {
			width: 116px;
			height: 23px;
			// width: 165px;
			// height: 33px;
		}
	}

	@media screen and (max-width: 960px) {
		z-index: 1;
		left: 2em;
		// .white-logo{
		// 	position: fixed;
		// }
	}
`;


export const Menu = styled.ul`	
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	padding-top: 30px;
	margin: 0em 1.25em;

	@media screen and (max-width: 960px) {
		height: 100vh;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow-x: hidden;
		position: fixed;
		top: 0;
		right: ${({ click }) => (click ? 0 : '-100%')};
		width: 100%;
		transition: 0.3s all ease-out;
		background-color: #000;

		
	}
`;




export const MenuItem = styled.li`
 height: 100%;
	padding: 0.5rem 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 960px) {
		padding: 0.2rem 1rem;
		width: 100%;
		height: 8%;	
	}
`;

export const MenuItemLink = styled.a`
	text-decoration: none;
	font-size: 15px;
	letter-spacing: 0.1em;
	font-weight: 400;
	color: #000;

	${({ isActive }) =>
		isActive &&
		css`
			color: #fff;
		`}

	&:hover {
		color: #fff;
	}
	@media screen and (max-width: 960px) {
		color: #fff;

		&:hover {
			color: #64dcff;
		}
	}
`;



export const Burguer = styled.div`
	display: none;
	margin-right: 2em;

	@media screen and (max-width: 960px) {
		display: flex;
		z-index: 1;

		//test
		.x-burguer {
			// position: fixed;
			right: 2em;
			top: 2.7em;
		}
	}
`;

export const DropdownContainer = styled.div`
	//  position: relative;
	//  display: inline-block;

	float: left;
	overflow: hidden;
	cursor: pointer;
`;



export const DropdownMenu = styled.div`
	cursor: pointer;
	display: ${({ open }) => (open ? 'block' : 'none')};
	position: absolute;

	// min-width: 100px;
	// box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1000;
	right: 50px;
	top: 100%;

	@media screen and (max-width: 960px) {
		display: ${({ open }) => (open ? 'block' : 'none')};
		position: absolute;

		min-width: 100px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1000;
		left: 37%;
		top: 75%;
	}
`;

export const DropdownItem = styled.div`
	// color: #fff;
	a {
		float: none;
		color: ${({ selected }) => (selected ? '#fff' : '#000')};
		padding: 0.3em;
		text-decoration: none;
		display: block;
		text-align: center;

		&:hover {
			color: ${({ selected }) => (selected ? '#fff' : '#000')};
		}
	}

	@media screen and (max-width: 960px) {
		a {
			
			color: ${({ selected }) => (selected ? '#64dcff' : '#fff')};
			

			&:hover {
				color: ${({ selected }) => (selected ? '#64dcff' : '#fff')};
			}
		}
	}
`;