/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	SubText,
	
	TextContainer,
} from '../../../styles/Authors/TextAuthors2Styles';
import { useTranslation } from 'react-i18next';
const TextAuthors2 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>{t('authors.text4')}</Title>
			<SubTitle>{t('authors.text5')}</SubTitle>
			<SubText>{t('authors.text6')}</SubText>
			{/* <Title>Give value to your work!</Title>
			<SubTitle>
				Transform your literature into NFTs, using blockchain for 
				copyright protection and limited editions.
			</SubTitle>
			<SubText>
				Discover opportunities to generate revenue from your creations and
				prevent them from being forgotten.			
			</SubText> */}
		</TextContainer>
	);
};

export default TextAuthors2;
