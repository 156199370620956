/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Title, 
	 TextContainer,
	 LinkFile,
	} from '../../../styles/AboutUs/TextAbout6Styles'
import { useTranslation } from 'react-i18next';

const TextAbout6 = ({ handleDownloadClick }) => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>
				{t('about.text13')}{' '}
				<span>
					<LinkFile onClick={handleDownloadClick}>
						{t('about.text14')}{' '}
					</LinkFile>
				</span>
				{t('about.text15')}
			</Title>
			{/* <Title>
				Download our{' '}
				<span>
					<LinkFile onClick={handleDownloadClick}>
						Whitepaper{' '}
					</LinkFile>
				</span>
				to learn more about Liberada. In it you will find details about our
				platform, our vision and how you can join our community.
			</Title> */}
		</TextContainer>
	);
};

export default TextAbout6;
