import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';
import { Link } from 'react-router-dom';


export const TextContainer = styled.div`
	display: flex;
	width: 100%;

	justify-content: center;
	align-items: center;
	text-align: center;

	@media only screen and ${breakpoint.device.m} {
		margin: auto;
		width: 100%;
	}
	@media only screen and ${breakpoint.device.s} {
		margin: auto;
		width: 100%;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 100%;
		margin: auto;
	}
`;

export const Title = styled.h1`
	margin: 4em 1em 2em 1em;
	width: 56%;
	text-align: center;

	text-align: center;
	
	span {
		color: #fff;

	}

	@media only screen and ${breakpoint.device.m} {
		padding-left: 1em;
		margin: 4.5em 1em 4.5em 1em;
		width: 67%;
		font-size: 60px;
		text-align: left;
	}

	@media only screen and ${breakpoint.device.s} {
		padding: 0;
		margin: 6.3em 1em 2em 1em;
		width: 81%;
		font-size: 40px;
		text-align: left;
	}
	@media only screen and ${breakpoint.device.xs} {
		padding: 0;
		margin: 6em 0em 5em 0em;
		width: 81%;
		font-size: 35px;
		text-align: left;
	}
`;
export const LinkMarketplace = styled(Link)`


		&:hover {
			text-decoration: underline;
		}
	
`;