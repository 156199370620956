/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	
	TextContainer,
} from '../../../styles/AboutUs/TextAbout2Styles';
import { useTranslation } from 'react-i18next';

const TextAbout2 = () => {
	const [t] = useTranslation();
	return (
		<TextContainer>
			<Title>{t('about.text3')}</Title>
			<SubTitle>{t('about.text4')}</SubTitle>

			{/* <Title>Say goodbye to editorials!</Title>
			<SubTitle>
				Liberada offers solutions to writers to guarantee copyright, add value
				and contribute to a sustainable environment.
			</SubTitle> */}
		</TextContainer>
	);
};

export default TextAbout2;
