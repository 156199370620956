import { Routes, Route, useLocation } from 'react-router-dom';
 import About from '../components/sections/About';
 import Authors from '../components/sections/Authors';
import Readers from './sections/Readers';
import ContactUs from './sections/ContactUs';
import FAQs from './sections/FAQs';
// import Marketplace from './sections/Marketplace';
// import DetailCard from './DetailCard';
// import { AnimatePresence } from 'framer-motion'


import Marketplace from './sections/Marketplace';
import PrivacyPolicy from './sections/PrivacyPolicy';


const AnimatedRoutes = () => {
     const location = useLocation();

	return (
		// <AnimatePresence>
		<Routes location={location} key={location.pathname}>
			<Route path='/' element={<About />} />
			<Route path='/authors' element={<Authors />} />
			<Route path='/readers' element={<Readers />} />
			<Route path='/contactus' element={<ContactUs />} />
			<Route path='/marketplace' element={<Marketplace />} />
			<Route path='/privacypolicy' element={<PrivacyPolicy />} />
			<Route path='/faqs' element={<FAQs />} />
		</Routes>
		// </AnimatePresence>
	);
};

export default AnimatedRoutes;
