import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';
import { Link } from 'react-router-dom';

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 66%;
	margin: 15em auto;

	@media only screen and ${breakpoint.device.m} {
		width: 72%;
		margin: 12em 6em 10em 6em;
	}

	@media only screen and ${breakpoint.device.s} {
		width: 79%;
		margin: 12em 3em 2em 3em;
	}
	@media only screen and ${breakpoint.device.xs} {
		width: 87%;
		margin: 10em 2em 2em 2em;
	}
`;

export const Title = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	weight: 400;

	font-size: 50px;
	margin: 0.5em;

	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

export const SubTitle = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	weight: 400;
	font-size: 30px;

	span {
		color: #fff;
	}

	@media only screen and ${breakpoint.device.m} {
		font-size: 24px;
	}
	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
	}
`;

export const LinkMarketplace = styled(Link)`
	&:hover {
		text-decoration: underline;
	}
`;


