import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';


export const TextContainer = styled.div`
	// width: 53%;
	width: 85%;
	margin: 20em auto;
	display: flex;
	flex-direction: column;

	@media only screen and ${breakpoint.device.lg} {
		margin: 10em auto;
		// margin: 5em auto;fixing
		width: 68%;
	}

	@media only screen and ${breakpoint.device.l} {
		margin: 16em auto;
		width: 100%;
	}

	@media only screen and ${breakpoint.device.m} {
		margin: auto;
		padding-top: 22em;
		padding-bottom: 3em;
		width: 70%;
		// margin: 6em 2em 6em 2em;
		// width: 90%;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 19em 2em 1em 2em;
		width: 81%;
		padding: 0;
	}
	@media only screen and ${breakpoint.device.xs} {
		margin: 12em 2em 1em 2em;
		width: 80%;
		padding: 0;
	}
`;


export const Title = styled.p`
	// padding-bottom: 1.8em;
	text-align: center;

	font-weight: 300;
	color: #fff;
	font-size: 30px;
	
	

	@media only screen and ${breakpoint.device.m} {
		font-size: 24px;
		padding-bottom: 0;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 20px;
		padding-bottom: 0;
	}
`;

export const SubTitle = styled.h2`
	text-align: center;
	font-size: 50px;
	weight: 400;

	margin: 0.5em auto;



	@media only screen and ${breakpoint.device.m} {
		margin: auto;
		font-size: 35px;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 10px auto;
		font-size: 30px;
	}
`;

