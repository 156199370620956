import styled from 'styled-components';

export const Section = styled.section`
	width: 100vw;
	height: 100vh;
`;

export const Background = styled.div`
	min-height: 100vh;
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
`;


