
import { AuthorsSection} from '../../styles/Authors/AuthorsStyles';

import AuthorsFullBg from '../../assets/images/AuthorsFullBg.svg';
import mobileImage from '../../assets/images/AuthorsMobile.svg';

 import NavbarWhite from '../NavbarWhite';
import { useState, useEffect } from 'react';
import TextAuthors from '../textComponents/Authors/TextAuthors';
import TextAuthors2 from '../textComponents/Authors/TextAuthors2';
import TextAuthors3 from '../textComponents/Authors/TextAuthors3';
 import TextAuthors4 from '../textComponents/Authors/TextAuthors4';
import Footer from '../Footer'

const Authors = () => {

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const imageUrl = windowWidth >= 700 ? AuthorsFullBg : mobileImage;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<>
			<AuthorsSection
				style={{
					backgroundImage: `url(${imageUrl})`,
				}}
			>
				<NavbarWhite />
				<TextAuthors />
				<TextAuthors2 />
				<TextAuthors3 />
				<TextAuthors4 />
			</AuthorsSection>
			<Footer />
		</>
	);
};

export default Authors;