import {
	TextContainer,
	TextBox,
	Text,
} from '../../../styles/ContactUs/TextContactUs2Styles';

const TextContactUs2 = () => {
	return (
		<TextContainer>
			<TextBox>
				<Text>
					<span>Tel.</span>
					<br></br>
					<a src='phoneLiberada' href='tel:+34607341813'>
						+34 607 341 813
					</a>
				</Text>
			</TextBox>
			<TextBox>
				<Text>
					<span>Mail</span>
					<br></br>
					<a href='mailto: contact@liberada.io'>contact@liberada.io</a>
				</Text>
			</TextBox>
			<TextBox>
				<Text>
					<span>Social</span>
					<br></br>
					<a
						src='linkedInLiberada'
						href='https://es.linkedin.com/company/liberada?trk=public_profile_topcard-current-company'
						target='_blank'
						rel='noreferrer'
					>
						LI
					</a>
					<a
						src='instagramLiberada'
						href='https://instagram.com/liberadanft?igshid=YmMyMTA2M2Y='
						target='_blank'
						rel='noreferrer'
					>
						IG
					</a>
					<a
						src='twitterLiberada'
						href='https://twitter.com/LiberadaNFT'
						target='_blank'
						rel='noreferrer'
					>
						TW
					</a>
					<a
						src='facebookLiberada'
						href='https://www.facebook.com/people/NFT-Books-Liberada/100090957735416/'
						target='_blank'
						rel='noreferrer'
					>
						FB
					</a>
				</Text>
			</TextBox>
		</TextContainer>
	);
};

export default TextContactUs2;
