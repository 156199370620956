import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';



export const TextContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;


	@media only screen and ${breakpoint.device.s} {
		// margin: 5% auto;
		
	}
`;


export const Title = styled.h1`
	margin: 5em auto;
	width: 57%;
	text-align: center;
	color: #fff;
	font-size: 40px;

	span {
		color: #64dcff;
	}

	@media only screen and ${breakpoint.device.s} {
		margin: 2em auto;
		font-size: 30px;
		width: 80%;
	}
`;


