import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import i18next from "i18next"
import { I18nextProvider } from 'react-i18next';
// import globalEng from "../public/translations/eng/global.json"
// import globalEsp from '../public/translations/esp/global.json';
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';

i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		interpolation: { escapeValue: true },
		fallbackLng: 'en',
		detection: {
			order: ['cookie', 'localStorage','htmlTag', 'path', 'subdomain'],
			caches: ['cookie'],
		},
		backend: {
			loadPath: 'locales/translations/{{lng}}/global.json',
		},
		react: { useSuspense: true},
	
	});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode> 
    
    <BrowserRouter> 
 <I18nextProvider i18n={i18next}>
  <App />
 </I18nextProvider> 
    </BrowserRouter>
  
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
