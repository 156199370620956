import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';

// export const AboutSection = styled.section`
// 	// width: 100vw;
// 	// height: 100vh;
// 	// overflow-x: hidden;
// `;
export const AboutSection= styled.section`
	background-color: #64dcff;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	
`;


export const ModalView = styled.div`
    position: relative;
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.01);
	backdrop-filter: blur(4px);
	z-index: 999;
`;

export const Modal = styled.div`
	position: absolute;
	bottom: 50%;
	right: 0;
	left: 0;

	// text-align: center;

	button {
		color: #000;
		display: block;
		padding: 0.5em;
		font-family: 'Tomorrow';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		margin: 1em auto;
		background: rgba(255, 255, 255, 0.01);
		backdrop-filter: blur(4px);
		// background-color: #fff;
		border: 2px solid #000000;
		cursor: pointer;

		&:hover {
			background-color: #000000;
			color: #fff;
		}
	}
`;
export const CloseBtn = styled.h2`
	cursor: pointer;
	position: absolute;

	right: 0;
	left: 6%;

	text-align: center;
	font-family: Roboto;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 157.5%;

	@media only screen and ${breakpoint.device.m} {
		left: 0;
	}
	@media only screen and ${breakpoint.device.s} {
		left: 0;
	}

	@media only screen and ${breakpoint.device.xs} {
		left: 0;
	}
`;