/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	TextContainer,
	MarketplaceLink,
} from '../../../styles/Readers/TextReaders3Styles';
import { useTranslation } from 'react-i18next';
const TextReaders3 = () => {
	const [t, i18n] = useTranslation('global');
	return (
		<TextContainer>
			<Title>{t('readers.text7')}</Title>
			<SubTitle>{t('readers.text8')}</SubTitle>
			<SubTitle>{t('readers.text9')}</SubTitle>
			<MarketplaceLink to='/marketplace'>{t('readers.text10')}</MarketplaceLink>
			{/* <Title>All in one!</Title>
			<SubTitle>
				With the purchase of an NFT book, you can also obtain exclusive
				collectibles such as quotes from the book in image format, images of
				passages, author audios, signed sketches, and merchandise.
			</SubTitle>
			<SubTitle>
				You can also participate in special events through the free Liberada
				token, such as live sessions with the authors, donations, meet & greet
				events, and physical book fairs.
			</SubTitle>
			<MarketplaceLink to="/marketplace">MARKETPLACE</MarketplaceLink> */}
		</TextContainer>
	);
};

export default TextReaders3;
