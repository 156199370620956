/* eslint-disable no-unused-vars */
import {
	Title,
	SubTitle,
	TextContainer,
} from '../../../styles/Readers/TextReaders2Styles';
import { useTranslation } from 'react-i18next';
const TextReaders2 = () => {
	const [t, i18n] = useTranslation('global');
	return (
		<TextContainer>
			<Title>{t('readers.text4')}</Title>
			<SubTitle>
				{t('readers.text5')} <br></br>
				{t('readers.text6')}
			</SubTitle>
		</TextContainer>
	);
};

export default TextReaders2;
