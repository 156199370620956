import styled from 'styled-components';
import breakpoint from '../Variables/MediaQ';
import { Link } from 'react-router-dom';
export const TextContainer = styled.div`
	width: 35%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 20em 0 20em 18em;
	padding-bottom: 2em;



	@media only screen and ${breakpoint.device.lg} {
		width: 40%;
		margin: 14em 0 14em 15em;
	}

	@media only screen and ${breakpoint.device.l} {
		padding: 0;
		margin: 10em 5em 8em 5em;
		width: 50%;
	}

	@media only screen and ${breakpoint.device.m} {
		padding: 0;
		margin: 10em 12em 8em 12em;
		width: 36%;
	}

	@media only screen and ${breakpoint.device.s} {
		padding: 0;
		margin: 5em 0 15em 2em;
		width: 63%;
	}

	@media only screen and ${breakpoint.device.xs} {
		margin: 7em 1.5em 8em 1.5em;
		width: 70%;
	}
`;

export const Title = styled.h2`
	// font-family: 'Tomorrow', sans-serif;
	font-style: normal;
	weight: 400;

	font-size: 50px;

	// margin: .5em .5em 0 0;


	@media only screen and ${breakpoint.device.m} {
		font-size: 35px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 30px;
	}
`;

export const SubTitle = styled.p`
	width: 94%;
	//  height: 80px;
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	margin: 1em 0 0;

	@media only screen and ${breakpoint.device.lg} {
		width: 96%;
	}
	@media only screen and ${breakpoint.device.m} {
		font-size: 16px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
export const MarketplaceLink = styled(Link)`
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	letter-spacing: 0.1em;
	color: #fff;
	margin: 2em 0 0;

	&:hover {
		text-decoration: underline;
	}
	@media only screen and ${breakpoint.device.m} {
		font-size: 14px;
	}

	@media only screen and ${breakpoint.device.s} {
		font-size: 14px;
	}
`;
